
import { Modal } from "react-responsive-modal";
import '../modal.scss';
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import MultiSelect from "../../../components/MultiSelect/Select";

interface ISignIn {
    isOpen: boolean;
    brands: any;
    stores: any;
    onClose: () => void;
    loading: boolean
    formData: FormActivation
    submitData: (brands: ISelect[] | null, store: ISelect[] | null) => void
}
interface ISelect {
    text: string,
    value: string
}

export interface FormActivation {
    store: ISelect[] | null;
    brands: ISelect[] | null;
};

const AddingRequest = ({ isOpen, onClose, loading, formData, submitData, brands, stores }: ISignIn): JSX.Element => {
    const [loadingForn, setLoadingForm] = useState(false);
    const [type, setType] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [stateData, setStateData] = useState([]);
    const [stateData2, setStateData2] = useState([]);
    const [errors, setErrors] = useState({});
    useEffect(() => {
        init();
    }, [])
    const init = () => {
        let filteredData2: any = [];

        setStateData(brands);
        filteredData2.push({
            text: 'Spid Pardo (Miraflores)',
            value: '1',
        }, {
            text: 'Wong Aldabas (Gardenias-Santiago de Surco)',
            value: '2',
        },
            {
                text: 'Wong Asia',
                value: '3',
            },
            {
                text: 'Wong Ate',
                value: '4',
            });
        setStateData2(filteredData2);
    }
    const handleChangeBrandInput = (newValue: ISelect[] | null) => {
        let errorsAux = { ...errors };
        formData.brands = newValue;
        setErrors(errorsAux);
    }
    const handleChangeStoreInput = (newValue: ISelect[] | null) => {
        let errorsAux = { ...errors };
        formData.store = newValue;
        setErrors(errorsAux);
    }
    const onSubmit = (e: any) => {
        e.preventDefault();
        let errorsAux: any = {};
        submitData(formData.brands?.length === 0 ? [] : formData.brands, formData.store?.length === 0 ? [] : formData.store);
        onClose();
        // if(Object.keys(errorsAux).length === 0) {
        //     submitData(formData.cityInput?.value || 0, formData.addressInput,
        //         formData.bankInput?.value || 0, formData.accountTypeInput?.value || 0, formData.accountNumberInput,
        //         validationData?.data?.documentType, validationData?.data?.fullName, validationData?.data?.documentNumber);
        // }
    };
    return (<Modal modalId="turn-my-tasks-modal" open={isOpen} classNames={{
        overlay: 'customOverlay',
        modal: 'general_modal_main',
    }} onClose={onClose}>

        <div className="signIn__panel_datos">
            <div className="general_model_conteiner">
                <div className="general_model_top_title">
                    Agregar activación
                </div>
                <div className="general_model_top_description">
                    Complete los siguientes campos para agregar una nueva activación.
                </div>

            </div>
            <form onSubmit={onSubmit} className="form-activaction">
                <MultiSelect
                    className="auth__field"
                    data={brands}
                    errors={errors}
                    label='¿Qué marca quieres activar?'
                    labelColor="#707070"
                    name="marca"
                    placeholder='Marcas'
                    onChange={(e: any) => handleChangeBrandInput(e)}
                    required={false}
                />
                <MultiSelect
                    className="auth__field"
                    data={stores}
                    errors={errors}
                    label='¿En cuales tiendas quieres activar?'
                    labelColor="#707070"
                    name="tiendas"
                    placeholder='Tiendas'
                    onChange={(e: any) => handleChangeStoreInput(e)}
                    required={false}
                />
                <div>
                    <div className="filter-footer">

                        <Button
                            size="large"
                            submit
                            type="primary"
                            text="Aceptar"
                        />
                    </div>
                </div>
            </form>
        </div>
    </Modal>)
};

export default AddingRequest;
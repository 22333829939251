import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from "react";
import { Icon, IconButton } from "@mui/material";
import shop from '../../../assets/images/fi-rr-shop.svg';
import gps from '../../../assets/images/fi-rr-marker.svg';
import calendar from '../../../assets/images/fi-rr-calendar.svg';
import oclock from '../../../assets/images/oclock_g.svg';
import users from '../../../assets/images/fi-rr-user-black.svg';
import moment from "moment";
import { TurnDetail } from "../../../services/interfaces/TurnActivationDetail";
import EndHeader from '../../RequestActivation/components/EndSection';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


interface DataInterface {
    label: string;
    children: React.ReactNode;
}

interface ActivationDrawerSchema {
    dataSource: TurnDetail | null;
    isDrawerOpen: boolean;
    closeDrawer: () => void;
    loading?: boolean;
    initialTab?: number;
}
function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const ActivationDrawer = ({
    dataSource,
    isDrawerOpen,
    closeDrawer,
    loading = false,
    initialTab = 0,
}: ActivationDrawerSchema) => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (<>
        {isDrawerOpen && (
            <>
                <div className="rsm-fixed rsm-inset-0 rsm-opacity-50 rsm-z-40 rsm-bg-gray-700 modal-provider__main request_activation_drawer_animation"></div>
                <div className="rsm-fixed rsm-inset-0  rsm-flex rsm-justify-center rsm-items-center rsm-z-40 lateralBar">
                    <div className="rsm-bg-white rsm-rounded-md rsm-overflow-auto rsm-max-h-full rsm-w-full  rsm-opactiy-100 rsm-shadow-lg rsm-z-50 rsm-border rsm-border-gray-200 rsm-flex rsm-flex-col">
                        <EndHeader>
                            <div className="general_model_top_content__turn_detail_modal">
                                <CloseIcon
                                    className="general_modal_close"
                                    onClick={() => {
                                        if (!loading) {
                                            closeDrawer();
                                        }
                                    }}
                                />
                            </div>
                            <div className="general_container_end">
                                <div className="div_containe">
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab label="Detalles Generales" {...a11yProps(0)} />
                                        <Tab label="Personas asignadas" {...a11yProps(1)} />
                                    </Tabs>
                                    <CustomTabPanel value={value} index={0}>
                                        <div className="title_tab">Detalle del turno:</div>
                                        <div className="detail_one">
                                            <IconButton>
                                                <img src={shop} alt="store-icon" />
                                            </IconButton>
                                            {dataSource?.storeName}
                                        </div>
                                        <div className="detail_one">
                                            <IconButton>
                                                <img src={gps} alt="gps-icon" />
                                            </IconButton>
                                            {dataSource?.storeAddress}
                                        </div>
                                        <div className="detail_one">
                                            <IconButton>
                                                <img src={calendar} alt="date-icon" />
                                            </IconButton>
                                            {`${moment(dataSource?.turnStartOfExecutionDate).format('dddd DD [de] MMMM [del] YYYY')}`}
                                        </div>
                                        <div className="hourTurn">
                                            <div className="detail_one">
                                                <IconButton>
                                                    <img src={oclock} alt="hour-icon" />
                                                </IconButton>
                                                {`Inicio ${moment(dataSource?.turnStartOfExecutionDate).format('HH:mm')} hs.`}
                                            </div>
                                            <div className="detail_one">
                                                <IconButton>
                                                    <img src={oclock} alt="hour-icon" />
                                                </IconButton>
                                                {`Fin ${moment(dataSource?.turnEndOfExecutionDate).format('HH:mm')} hs.`}
                                            </div>
                                        </div>
                                        
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1}>
                                        {dataSource?.workers.map((value,index)=>(
                                            <div className="detail_one">
                                                <IconButton>
                                                    <img src={users} alt="date-icon" />
                                                </IconButton>
                                                {value.userFullname}
                                        </div>
                                        ))}
                                        
                                    </CustomTabPanel>
                                </div>
                            </div>
                        </EndHeader>
                    </div>
                </div>
            </>
        )}
    </>)
}

export default ActivationDrawer;
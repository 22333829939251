import React, { createRef, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import FilterDetail from "../../components/Organisms/FilterDetail/FilterDetail";
import ActivationService from "../../services/ActivationService";
import { ActivationHistoryRow } from "../../services/interfaces/ActivationInterface";
import HistoryFilterOption from "./components/HistoryFilterOption";
import "./styles.scss";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import moment from "moment";
import Header from "../Header/Header";
import { FilterDetailData } from "../../components/Organisms/FilterDetail/FilterDetail.types";
import TextInput from "../../components/Organisms/TextInput/TextInput";
import { DataInterface } from "../../components/Atoms/Select/Select";
import LoaderLocal from "../../components/Atoms/LoaderLocal/LoaderLocal";
import SimpleTable from "../../components/SimpleTable/SimpleTable";
import {
  ColumnSimpleTableSchema,
  CustomSimpleTableRef,
  DataSourceSimpleTableSchema,
} from "../../components/SimpleTable/SimpleTable.types";
import { generateUniqueId } from "../../utils/helpers";
import TenantButton from "../../components/TenantButton/TenantButton";

const columns: ColumnSimpleTableSchema[] = [
  {
    title: "N° DE ACTIVACIÓN",
    index: "requestNumberFormat",
    align: "left",
  },
  {
    title: "MARCA",
    index: "brand",
    align: "left",
  },
  {
    title: "FECHA",
    index: "date",
    align: "left",
  },
  {
    title: "ESTADO",
    index: "statusFormat",
    align: "left",
  },
];

const statuses: DataInterface[] = [
  { value: "SEND", text: "Enviada" },
  { value: "APPROVED", text: "Aprobada" },
  { value: "CANCELLED", text: "Cancelada" },
];

const RequestHistory = (): JSX.Element => {
  const [currentDataSource, setCurrentDataSource] = useState<any[]>([]);
  const allStates = statuses.map((statusRow) => statusRow.value);
  const mainTableRef = createRef<CustomSimpleTableRef<any>>();
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableRefreshUqId, setTableRefreshUqId] = useState(generateUniqueId());
  const heightOffset = 140;
  const vh = window.innerHeight;
  const vw = window.innerWidth;
  /* Filters */
  const [termFilter, setTermFilter] = useState<string | undefined>(undefined);
  const [termFilterTemp, setTermFilterTemp] = useState<string>("");
  const [startDateFilter, setStartDateFilter] = useState(
    moment().add(-3, "months").format("YYYY-MM-DD")
  );
  const [startDateFilterTemp, setStartDateFilterTemp] = useState(
    moment().add(-3, "months").toDate()
  );
  const [endDateFilter, setEndDateFilter] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [endDateFilterTemp, setEndDateFilterTemp] = useState(moment().toDate());
  const [statusesFilterTemp, setStatusesFilterTemp] = useState<string[]>([]);
  const [statusesFilter, setStatusesFilter] = useState<string[]>(allStates);

  /* Filter Menú */
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setTableLoading(true);
    if (!loading) {
      mainTableRef.current?.fetchData(1);
    }
  }, [
    termFilter,
    `${startDateFilter}-${endDateFilter}`,
    statusesFilter,
    tableRefreshUqId,
  ]);

  const showActivationDetail = async (activationId: number) => {
    navigate(`/history-activation/detail/${activationId}`);
  };

  const executeTermFilter = () => {
    if (termFilterTemp.length > 0) {
      setTermFilter(termFilterTemp ? termFilterTemp.trim() : undefined);
    } else {
      setTermFilter(undefined);
    }
  };

  const reloadGrid = () => {
    setTableRefreshUqId(generateUniqueId());
  };

  const getActiveFilters = (): FilterDetailData[] => {
    const sd = moment(startDateFilter, "YYYY-MM-DD").format("DD/MM/YYYY");
    const ed = moment(endDateFilter, "YYYY-MM-DD").format("DD/MM/YYYY");

    const filters: FilterDetailData[] = [
      {
        key: "DATE_RANGE_FILTER",
        label: "Fecha",
        detail: `Del ${sd} al ${ed}`,
        disableRemove: true,
      },
    ];

    if (termFilter && termFilter.length > 0) {
      filters.push({
        key: "TERM_FILTER",
        label: "N° de activación",
        detail: termFilter,
      });
    }

    if (
      statusesFilter.length > 0 &&
      statusesFilter.length !== statuses.length
    ) {
      const statusesNamesSelected = statuses
        .filter((statusRow) => {
          return statusesFilter.includes(statusRow.value);
        })
        .map((statusRow) => statusRow.text);
      const statusesFilterFormat =
        statusesFilter.length <= 4
          ? statusesNamesSelected.join(", ")
          : `${statusesFilter.length} estados seleccionados`;
      filters.push({
        key: "STATUS_FILTER",
        label: "Estado",
        detail: (
          <span
            title={
              statusesFilter.length > 4
                ? `Estados: ${statusesNamesSelected.join(", ")}`
                : ""
            }
          >
            {statusesFilterFormat}
          </span>
        ),
      });
    }

    return filters;
  };
  const filterSubmit = (
    startDateParam: string,
    endDateParam: string,
    statusesParam: string[]
  ) => {
    setStartDateFilter(startDateParam);
    setEndDateFilter(endDateParam);
    setStatusesFilter(statusesParam);
  };

  const fetchData = async (
    pageSize: number,
    currentPage: number
  ): Promise<DataSourceSimpleTableSchema<any>> => {
    setTableLoading(true);

    const historyResponse = await ActivationService.getAllPaginate({
      page: currentPage,
      pageSize: pageSize,
      startDate: startDateFilter,
      endDate: endDateFilter,
      status: statusesFilter.length === 3 ? [] : statusesFilter,
      term: termFilter,
    });

    setTableLoading(false);

    if (historyResponse.success) {
      const activationsData = historyResponse.data.data.map(
        (row: ActivationHistoryRow) => {
          const statusFormat =
            row.status === "SEND"
              ? "Enviada"
              : row.status === "CANCELLED"
              ? "Cancelada"
              : row.status === "APPROVED"
              ? "Aprobada"
              : "Desconocido";
          return {
            id: row.id,
            requestNumber: row.requestNumber,
            requestNumberFormat: (
              <span
                className="activation_history_request_number"
                onClick={() => {
                  showActivationDetail(row.id);
                }}
              >
                {row.requestNumber}
              </span>
            ),
            brand: row.brandName,
            date: moment(new Date(row.createdAt)).format("DD/MM/YYYY"),
            statusFormat: (
              <span
                className={`activation_history_status activation_history_status__${row.status}`}
              >
                {statusFormat}
              </span>
            ),
          };
        }
      );
      return {
        items: activationsData,
        totalRows: parseInt(historyResponse.data.totalRowsFiltered),
      };
    }

    return {
      items: [],
      totalRows: 0,
    };
  };

  const clearFilters = () => {
    setTermFilter(undefined);
    setStatusesFilter(allStates);
  };

  const removeFilter = (key: string) => {
    switch (key) {
      case "TERM_FILTER":
        setTermFilter(undefined);
        break;
      case "STATUS_FILTER":
        setStatusesFilter(allStates);
        break;
    }
  };

  return (
    <Header>
      {loading ? (
        <LoaderLocal />
      ) : (
        <>
          <div
            className="app__main"
            style={{
              width: `${vw}px`,
              height: `${vh - heightOffset}px`,
            }}
          >
            <div className="app__content">
              <div className="title">Historial de solicitudes</div>
              <div className="assigned__main">
                <div className="header__options">
                  <div className="first__options">
                    <div className="searchbar__main_h">
                      <div className="searchbar__content">
                        <TextInput
                          className="searchbar__input"
                          name="searched"
                          onChange={({ target }: any) =>
                            setTermFilterTemp(target.value)
                          }
                          onEnterAction={() => {
                            executeTermFilter();
                          }}
                          placeholder={`Buscar por n° de activación`}
                          inputPropsAdornments={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                onClick={executeTermFilter}
                              >
                                <div className="searchbar__icon__main_h">
                                  <SearchIcon className="searchbar__icon searchbar__button" />
                                </div>
                              </InputAdornment>
                            ),
                            required: true,
                          }}
                          value={termFilterTemp}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="second__options">
                    <HistoryFilterOption
                      isFilterOpen={isFilterOpen}
                      setIsFilterOpen={setIsFilterOpen}
                      startDate={startDateFilterTemp}
                      currentStartDate={startDateFilter}
                      setStartDate={setStartDateFilterTemp}
                      endDate={endDateFilterTemp}
                      currentEndDate={endDateFilter}
                      setEndDate={setEndDateFilterTemp}
                      statusesSelected={statusesFilterTemp}
                      setStatusesSelected={setStatusesFilterTemp}
                      currentStatusesSelected={statusesFilter}
                      submit={filterSubmit}
                      statuses={statuses}
                    />

                    <div style={{ width: "200px" }}>
                      <TenantButton
                        text={
                          <>
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5Z" />
                            </svg>
                            <span>Solicitar activación</span>
                          </>
                        }
                        action={() =>
                          navigate("/history-activation/request-activation")
                        }
                        fullwidth
                      />
                    </div>
                  </div>
                </div>

                {/* filter details */}
                <FilterDetail
                  filters={getActiveFilters()}
                  cleanFilters={clearFilters}
                  removeFilter={removeFilter}
                  loading={tableLoading}
                  hideClearAllFilters
                />
              </div>
              <SimpleTable
                ref={mainTableRef}
                columns={columns}
                request={fetchData}
                initialDefaultPageSize={8}
                loading={tableLoading}
              />
            </div>
          </div>
        </>
      )}
    </Header>
  );
};
export default RequestHistory;

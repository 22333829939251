import React, { useEffect, useState } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';

import calendar from '../../../assets/images/fi-rr-calendar.svg';
import { IconButton } from '@mui/material';
import moment from 'moment';

interface ActivationCalendarSchema {
  selectedDates: DateObject;
  setSelectedDates: (selectedDates: DateObject) => void;
}

function ActivationCalendar({
  selectedDates,
  setSelectedDates,
}: ActivationCalendarSchema):JSX.Element {
  const [value, setValue] = useState<DateObject>(new DateObject());
  const handleDateChange = (newDates: DateObject) => {
    setValue(newDates);
    setSelectedDates(newDates);
  };
    
  return (
    <DatePicker
      format={'DD/MM/AAAA'}
      placeholder="DD/MM/AAAA"
      headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
      weekStartDayIndex={0}
      mapDays={({ date, selectedDate, currentMonth, isSameDate }) => {
        const startOfWeek = moment(value.format('YYYY-MM-DD')).startOf('week').day(0); // Configura el inicio de la semana en domingo
        const endOfWeek = moment(startOfWeek.format('YYYY-MM-DD')).endOf('week').day(6);
        const days_complete = [];
        let currentDay = moment(startOfWeek);
        while (currentDay.isSameOrBefore(endOfWeek, 'day')) {
            if(moment(currentDay).format('DD')!==moment(value.format('YYYY-MM-DD')).format('DD')){
              days_complete.push(moment(currentDay).format('YYYY-MM-DD'));
            }
            currentDay.add(1, 'days');
        }
        if(days_complete.includes(date.format('YYYY-MM-DD'))){
          return {className:'multiSelect' }
        }
        if(moment(value.format('YYYY-MM-DD')).format('YYYY-MM-DD')===date.format('YYYY-MM-DD')){
          return {
            className:'selectActiveToday',
          }
        }else{
          return {
            className:'desSelect',
          }
        }
      }}
      render={(value, openCalendar) => {
        return (
          <div style={{ cursor: 'pointer' }} className="input-select-date" onClick={openCalendar}>
            <IconButton>
              <img src={calendar} alt="calendar-icon" />
            </IconButton>
            <span>
              Seleccionar semana
            </span>
          </div>
        );
      }}
      onChange={handleDateChange}
      months={[
        ['Enero', 'Ene.'],
        ['Febrero', 'Feb.'],
        ['Marzo', 'Mar.'],
        ['Abril', 'Abr.'],
        ['Mayo', 'May.'],
        ['Junio', 'Jun.'],
        ['Julio', 'Jul.'],
        ['Agosto', 'Ago.'],
        ['Septiembre', 'Sep.'],
        ['Octubre', 'Oct.'],
        ['Noviembre', 'Nov.'],
        ['Diciembre', 'Dic.'],
      ]}
      weekDays={['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']}
    />
  );
}

export default ActivationCalendar;

import { IconButton } from "@mui/material";
import ActivationCalendar from "./components/ActivationCalendar";
import { DateObject } from "react-multi-date-picker";
import shop from "../../assets/images/fi-rr-shop.svg";

import moment from "moment";
import { useEffect, useState } from "react";
import { now } from "lodash";
import ActivationDrawer from "./components/ActivationDrawer";
import {
  Filter,
  Turn,
  TurnsAndStores,
} from "../../services/interfaces/TurnActivation";
import TurnActivationService from "../../services/TurnsActivationService";
import { TurnDetail } from "../../services/interfaces/TurnActivationDetail";
import Header from "../Header/Header";
import { FULL_COLUMN_SPAN } from "../RequestActivation/components/ActivationTable";

import "./styles.scss";

const TurnActivation = (): JSX.Element => {
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [dataSource, setDatasource] = useState<any[]>([]);
  const [data, setData] = useState<TurnsAndStores | null>(null);
  const [dataDetail, setDataDetail] = useState<TurnDetail | null>(null);
  const turnsByDate: { [key: string]: Turn[] } = {};
  const [dates, setDates] = useState<Array<string>>([]);
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [timeZone, setTimeZone] = useState<string | null>(null);
  const heightOffset = 140;
  const vh = window.innerHeight;
  const vw = window.innerWidth;
  function groupTurnsByDate(turn: Turn) {
    const { startExecutionDate } = turn;

    // Si la fecha ya existe en el objeto, agrega el turno; de lo contrario, crea una nueva entrada
    if (turnsByDate[startExecutionDate]) {
      turnsByDate[startExecutionDate].push(turn);
    } else {
      turnsByDate[startExecutionDate] = [turn];
    }
  }
  useEffect(() => {
    init();
  }, []);
  const handleDateChange = async (newDates: DateObject) => {
    const days = getWeekDays(newDates);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const filter = {
      startDate: days[0],
      endDate: days[6],
      timezone: timeZone,
    };
    await getAllTurnsActivations(filter);
    setDates(days);
  };
  const getAllTurnsActivations = async (filter: Filter) => {
    const turnDataResponse = await TurnActivationService.getAllTurnsSchedule(
      filter
    );
    if (turnDataResponse.success) {
      setLoadingTable(true);
      if (turnDataResponse.data.stores.length > 0) {
        setData(turnDataResponse.data);
      } else {
        setData(null);
      }
    } else {
      setLoadingTable(false);
      setData(null);
    }
  };
  const getById = async (id: number) => {
    const TurnDetailResponse = await TurnActivationService.getById(id);
    if (TurnDetailResponse.success) {
      setIsOpen(true);
      setDataDetail(TurnDetailResponse.data);
    } else {
    }
  };
  const getWeekDays = (selectedDate: DateObject) => {
    const startOfWeek = moment(selectedDate.format("YYYY-MM-DD"))
      .startOf("week")
      .day(0); // Configura el inicio de la semana en domingo
    const endOfWeek = moment(startOfWeek.format("YYYY-MM-DD"))
      .endOf("week")
      .day(6); // Configura el fin de la semana en sábado
    const days = [];
    let currentDay = moment(startOfWeek);

    while (currentDay.isSameOrBefore(endOfWeek, "day")) {
      days.push(moment(currentDay).format("YYYY-MM-DD"));
      currentDay.add(1, "days");
    }
    return days;
  };
  const init = async () => {
    setLoadingTable(false);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(timeZone);
    const momentDate = moment().format("YYYY-MM-DD");

    const days = getWeekDays(new DateObject(momentDate));
    const filter = {
      startDate: days[0],
      endDate: days[6],
      timezone: timeZone,
    };
    await getAllTurnsActivations(filter);
    setDates(days);
  };

  const getStoreDatesFormat = (): DateObject => {
    const momentDate = moment(now(), "YYYY-MM-DD");
    return new DateObject(momentDate.toDate());
  };
  const viewDetail = async (idTurn: number) => {
    getById(idTurn);
  };

  return (
    <>
      <Header>
        <div
          className="app__main"
          style={{
            width: `${vw}px`,
            height: `${vh - heightOffset}px`,
          }}
        >
          <div className="app__content">
            <div className="title">Programación de turnos</div>
            <div className="request_activation_adding_container">
              <ActivationCalendar
                selectedDates={getStoreDatesFormat()}
                setSelectedDates={(newDates: DateObject) => {
                  handleDateChange(newDates);
                }}
              />
            </div>
            {dates.length > 0 && (
              <>
                <table className="table_turns" cellPadding={0} cellSpacing={0}>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="text"
                          placeholder="Buscar tienda"
                          value={searchTerm}
                          className={`search-input ${
                            isInputEmpty ? "" : "empty"
                          }`}
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setIsInputEmpty(e.target.value === "");
                          }}
                        />
                      </th>
                      {dates.map((d) => {
                        return (
                          <>
                            <th key={d}>
                              {moment(d).format("ddd")}{" "}
                              <span>{moment(d).format("DD")}</span>
                            </th>
                          </>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {loadingTable && data?.stores ? (
                      <>
                        {data.stores
                          .filter(
                            (storeData) =>
                              searchTerm === "" ||
                              storeData.name
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                          )
                          .map((storeData, idx) => (
                            <tr className="table_body_tr">
                              <td key={idx}>
                                <div className="store_label">
                                  <div className="icon_calendar">
                                    <IconButton>
                                      <img src={shop} alt="calendar-icon" />
                                    </IconButton>
                                  </div>
                                  {storeData.name}
                                </div>
                              </td>
                              {dates.map((date) => (
                                <td key={date}>
                                  {storeData.turns.some(
                                    (turn) => turn.startExecutionDate === date
                                  ) ? (
                                    <>
                                      {storeData.turns
                                        .filter(
                                          (turn) =>
                                            turn.startExecutionDate === date
                                        )
                                        .map((turn) => (
                                          <div
                                            key={turn.id}
                                            className="horario_turn"
                                            onClick={() => viewDetail(turn.id)}
                                          >
                                            {/* <div className="icon_calendar">
                                                                                                <IconButton>
                                                                                                    <img src={calendarWhite} alt="calendar-icon" />
                                                                                                </IconButton>
                                                                                            </div> */}
                                            {`De ${moment(
                                              turn.startExecutionDateWithTime
                                            ).format("HH:mm")} a ${moment(
                                              turn.endExecutionDateWithTime
                                            ).format("HH:mm")}`}
                                          </div>
                                        ))}
                                    </>
                                  ) : (
                                    <div className="horario_turn-vacio"></div>
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td
                            colSpan={FULL_COLUMN_SPAN}
                            className={"activation_table_empty_rows_td"}
                          >
                            <span>
                              No se cuenta con ninguna tienda con turnos
                              disponibles en este rango de fechas.
                            </span>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </Header>
      <ActivationDrawer
        dataSource={dataDetail}
        isDrawerOpen={isOpen}
        closeDrawer={() => {
          setIsOpen(false);
        }}
        loading={loadingDrawer}
      />
    </>
  );
};

export default TurnActivation;

import React from 'react';
import { useSelector } from 'react-redux';

import { Tenant } from '../../../models/Tenant';
import {
  ActivationDetail,
  Payment,
} from '../../../services/interfaces/ActivationInterface';
import { getMoneyFormat } from '../../../utils/helpers';
import moment from 'moment';

export interface GeneralDetailSchema {
  activationDetail: ActivationDetail | null;
  payment: Payment | null;
  stores: any[];
}

function GeneralDetail({
  activationDetail,
  payment,
  stores,
}: GeneralDetailSchema) {
  const tenantClient: Tenant = useSelector((state: any) => state.tenant);
  const moneySymbol = tenantClient.currency.symbol;

  const getStoreNameById = (id: number) => {
    const store = stores.find((store: any) => {
      return store.id === id;
    });
    if (store) {
      return store.name;
    }
    return `Tienda desconocida (${id})`;
  };

  const getPaymentSubtotalByActivation = () => {
    const localPayment = payment?.details.find((paymentRow) => {
      return paymentRow.activationId === activationDetail?.id;
    });

    return localPayment ? parseFloat(`${localPayment.totalAmount}`) : 0;
  };

  const getFormattedDates = (dates: string[]) => {
    return dates
      .map((date) => {
        return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
      })
      .join(', ');
  };

  const getFormattedStatus = (status: 'SEND' | 'APPROVED' | 'CANCELLED') => {
    return status === 'APPROVED'
      ? 'aprobada'
      : status === 'CANCELLED'
      ? 'cancelada'
      : 'enviada';
  };

  return (
    <>
      {activationDetail && payment && (
        <div>
          <span className="activation_detail_general_detail_text_date">
            {moment(activationDetail.createdAt).format('DD/MM/YYYY')}
          </span>
          <h1 className="activation_detail_general_detail_text_title">
            Detalle de cotización{' '}
            <span>{getFormattedStatus(activationDetail.status)}</span>
          </h1>
          <h2 className="activation_detail_general_detail_text_brand">
            {activationDetail.brand.name}
          </h2>
          <p className="activation_detail_general_detail_text_activation_type">
            Tipo de activación:{' '}
            <span>{activationDetail.activationType.name}</span>
          </p>
          <div>
            <table className="activation_detail_general_detail_table">
              <thead>
                <tr>
                  <th>Tienda</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                {activationDetail.activationStores.map((activationStore) => (
                  <tr key={`row_${activationStore.storeId}`}>
                    <td>
                      <span className="activation_detail_general_detail_subtitle">
                        {getStoreNameById(activationStore.storeId)}
                      </span>
                      <br />
                      <span>Colaboradores:</span>
                      <span className="activation_detail_general_detail_bold">
                        {' ' + activationStore.numberOfCollaborators}
                      </span>
                      <br />
                      <span>Días de activación:</span>
                      <span
                        className="activation_detail_general_detail_bold activation_detail_general_detail_dates"
                        title={getFormattedDates(activationStore.dates)}
                      >
                        {' ' + activationStore.dates.length}
                      </span>
                    </td>
                    <td>
                      <span className="activation_detail_general_detail_bold">
                        {getMoneyFormat(
                          activationStore.totalAmount,
                          moneySymbol
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
                <tr key={`ft_row_subtotal`}>
                  <td>
                    <span className="activation_detail_general_detail_bold activation_detail_general_detail_subtotal">
                      Subtotal
                    </span>
                  </td>
                  <td>
                    <span className="activation_detail_general_detail_bold activation_detail_general_detail_subtotal">
                      {getMoneyFormat(
                        getPaymentSubtotalByActivation(),
                        moneySymbol
                      )}
                    </span>
                  </td>
                </tr>
                <tr key={`ft_row_taxes`}>
                  <td>
                    <span className="activation_detail_general_detail_bold activation_detail_general_detail_subtotal">
                      Impuestos
                    </span>
                  </td>
                  <td>
                    <span className="activation_detail_general_detail_bold activation_detail_general_detail_subtotal">
                      {(parseFloat(`${payment.taxRate}`) * 100).toFixed(2) +
                        '%'}
                    </span>
                  </td>
                </tr>
                <tr key={`ft_row_total`}>
                  <td>
                    <span className="activation_detail_general_detail_bold activation_detail_general_detail_total">
                      TOTAL
                    </span>
                  </td>
                  <td>
                    <span className="activation_detail_general_detail_bold activation_detail_general_detail_total">
                      {getMoneyFormat(
                        getPaymentSubtotalByActivation() *
                          (parseFloat(`${payment.taxRate}`) + 1),
                        moneySymbol
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default GeneralDetail;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useModal } from "react-simple-hook-modal";
import { toast } from "react-toastify";

import AlterTenantButton from "../../components/AlterTenantButton/AlterTenantButton";
import TenantAlertMessage from "../../components/TenantAlertMessage/TenantAlertMessage";
import TenantButton from "../../components/TenantButton/TenantButton";
import { Tenant } from "../../models/Tenant";
import ActivationService from "../../services/ActivationService";
import { ActivationCreate } from "../../services/interfaces/ActivationInterface";
import { generateRequestNumber } from "../../utils/helpers";
import ActivationDrawer from "./components/ActivationDrawer";
import ActivationTable from "./components/ActivationTable";
import {
  ActivationTableRowData,
  ActivationTableRowStoreData,
} from "./components/ActivationTableRow";
import AddingRequest, { FormActivation } from "./components/ModalAdding";
import moment from "moment";
import ActivationTypeService from "../../services/ActivationTypeService";
import BrandService from "../../services/BrandService";
import StoreService from "../../services/StoreService";
import Select from "../../components/Select/Select";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";

import "./styles.scss";

interface ISelect {
  text: string;
  value: string;
}

const RequestHistory = (): JSX.Element => {
  const navigate = useNavigate();
  const tenantClient: Tenant = useSelector((state: any) => state.tenant);
  const [dataSource, setDatasource] = useState<ActivationTableRowData[]>([]);
  const [brandsData, setBrandsData] = useState([] as any);
  const [storesData, setStoresData] = useState([] as any);
  const [stateData, setStateData] = useState([]);
  const { isModalOpen, openModal, closeModal } = useModal();
  const [loadingModal, setLoadingModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activationTypeIdSelected, setActivationTypeIdSelected] = useState<
    number | null
  >(null);
  const [formData] = useState<FormActivation>({ store: null, brands: null });
  const [prefix, setPrefix] = useState(
    tenantClient.activationConfig.requestPrefix
  );
  const [lastRequestNumber, setLastRequestNumber] = useState(120);
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const heightOffset = 140;
  const vh = window.innerHeight;
  const vw = window.innerWidth;

  const {
    isModalOpen: isModalOpenSuccess,
    openModal: openModalSuccess,
    closeModal: closeModalSuccess,
  } = useModal();

  const onChangeSelect = (event: any) => {
    if (event) {
      setActivationTypeIdSelected(event.value);
    } else {
      setActivationTypeIdSelected(null);
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#fff";
    init();
  }, []);

  const init = async () => {
    let filteredData: any = [];
    let brandData: any = [];
    let storesData: any = [];

    const [types, brands, stores, lastRequestNumberResponse] =
      await Promise.all([
        ActivationTypeService.get(),
        BrandService.get(),
        StoreService.get(),
        ActivationService.getLastRequestNumber(),
      ]);

    if (types.success) {
      filteredData = types.data?.map((value: any) => {
        return { text: value.name, value: value.id };
      });
    }
    if (brands.success) {
      brandData = brands.data?.map((value: any) => {
        return { text: value.name, value: value.id };
      });
    }
    if (stores.success) {
      storesData = stores.data?.map((value: any) => {
        return { text: value.name, value: value.id };
      });
    }
    setStateData(filteredData);
    setBrandsData(brandData);
    setStoresData(storesData);
    setLastRequestNumber(lastRequestNumberResponse.data.requestNumber + 1);
    setPrefix(lastRequestNumberResponse.data.prefix);
  };

  const addNewActivationConfig = (
    brands: ISelect[] | null,
    stores: ISelect[] | null
  ) => {
    if (brands && stores) {
      let localLastRequestNumber = lastRequestNumber;
      const localDatasource = [...dataSource];

      for (const brand of brands) {
        const brandIdx = localDatasource.findIndex((localRow) => {
          return localRow.brandId === parseInt(brand.value);
        });

        if (brandIdx === -1) {
          const storesPerBrand: ActivationTableRowStoreData[] = [];

          for (const store of stores) {
            storesPerBrand.push({
              id: parseInt(store.value),
              name: store.text,
              dates: [moment().format("YYYY-MM-DD")],
              numberOfCollaborators: 1,
            });
          }

          const newBrand: ActivationTableRowData = {
            requestNumber: generateRequestNumber(
              prefix,
              localLastRequestNumber++
            ),
            brandId: parseInt(brand.value),
            brandName: brand.text,
            stores: storesPerBrand,
          };
          localDatasource.push(newBrand);
        } else {
          const localBrand = localDatasource[brandIdx];
          const localStores = [...localBrand.stores];

          for (const store of stores) {
            const checkStore = localBrand.stores.findIndex((storeRow) => {
              return storeRow.id === parseInt(store.value);
            });

            if (checkStore === -1) {
              localStores.push({
                id: parseInt(store.value),
                name: store.text,
                dates: [moment().format("YYYY-MM-DD")],
                numberOfCollaborators: 1,
              });
            }
          }

          localDatasource[brandIdx].stores = localStores;
        }

        setLastRequestNumber(localLastRequestNumber);
        setDatasource(localDatasource);
      }
    }
  };

  const calculateAmountView = () => {
    setIsOpen(true);
  };

  const generateRequestPerActivations = async (
    dataFormat: ActivationCreate
  ) => {
    setLoadingDrawer(true);

    //TODO: queda validar que no se tenga las fechas vacías

    const newRequestActivationResponse = await ActivationService.create(
      dataFormat
    );

    if (newRequestActivationResponse.success) {
      await updateLastRequestNumber();

      if (dataFormat.activations.length === 1) {
        setSuccessMessage(
          "¡Felicidades!, se generó la solicitud de activación para la marca con éxito."
        );
      } else {
        setSuccessMessage(
          `¡Felicidades!, se generaron las solicitudes de activación para las ${dataFormat.activations.length} marcas con éxito`
        );
      }

      openModalSuccess();
    } else {
      const errorMessage =
        newRequestActivationResponse.data ||
        "Ocurrió un error en el servidor (500).";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoadingDrawer(false);
    }
  };

  const updateLastRequestNumber = async () => {
    const lastRequestNumberResponse =
      await ActivationService.getLastRequestNumber();

    if (lastRequestNumberResponse.success) {
      setLastRequestNumber(lastRequestNumberResponse.data.requestNumber);
    } else {
      const errorMessage =
        lastRequestNumberResponse.data ||
        "Ocurrió un error en el servidor (500).";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoadingDrawer(false);
    }
  };

  return (
    <>
      <Header>
        <div
          className="app__main"
          style={{
            width: `${vw}px`,
            height: `${vh - heightOffset}px`,
          }}
        >
          <div className="app__content">
            <div className="title">Solicitar activación</div>
            <div className="form__request__history">
              <Select
                className="select-form__activation"
                data={stateData}
                label="¿Qué tipo de activación quieres realizar?"
                onChange={onChangeSelect}
                labelColor="#707070"
                name="state"
                placeholder="Activación"
                required={false}
              />

              <div className="request_activation_adding_text">
                <AlterTenantButton
                  text={
                    <>
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5Z" />
                      </svg>
                      <span>Agregar activación</span>
                    </>
                  }
                  action={openModal}
                />
              </div>
            </div>

            <ActivationTable
              dataSource={dataSource}
              setDatasource={setDatasource}
            />

            <div className="request_activation_adding_container">
              <div style={{ width: "140px" }}>
                <TenantButton
                  text="Finalizar"
                  action={calculateAmountView}
                  fullwidth
                  disabled={
                    !(dataSource.length > 0 && activationTypeIdSelected)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Header>
      <AddingRequest
        isOpen={isModalOpen}
        onClose={closeModal}
        brands={brandsData}
        stores={storesData}
        loading={loadingModal}
        formData={formData}
        submitData={addNewActivationConfig}
      />

      {/* Drawer */}
      {activationTypeIdSelected && (
        <ActivationDrawer
          activationTypeId={activationTypeIdSelected}
          dataSource={dataSource}
          isDrawerOpen={isOpen}
          closeDrawer={() => {
            setIsOpen(false);
          }}
          action={generateRequestPerActivations}
          loading={loadingDrawer}
        />
      )}

      {/* Modals */}
      <TenantAlertMessage
        isModalOpen={isModalOpenSuccess}
        closeModal={closeModalSuccess}
        title="Activación generada"
        content={successMessage}
        action={async () => {
          closeModalSuccess();
          setIsOpen(false);
          setLoadingDrawer(false);
          setDatasource([]);
          navigate("/history-activation");
        }}
      />
    </>
  );
};
export default RequestHistory;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Checkbox from '../../../components/Checkbox/Checkbox';
import TenantButton from '../../../components/TenantButton/TenantButton';

import { ActivationCreate } from '../../../services/interfaces/ActivationInterface';
import { ActivationTableRowData } from './ActivationTableRow';
import EndHeader from './EndSection';
import CloseIcon from '@mui/icons-material/Close';
import { Tenant } from '../../../models/Tenant';

interface ActivationDrawerSchema {
  activationTypeId: number;
  dataSource: ActivationTableRowData[];
  isDrawerOpen: boolean;
  closeDrawer: () => void;
  action: (dataFormat: ActivationCreate) => void;
  loading?: boolean;
}

interface ActivationDrawerErrorSchema {
  activation_terms_conditions: string;
}

const ActivationDrawer = ({
  dataSource,
  isDrawerOpen,
  closeDrawer,
  action,
  loading = false,
  activationTypeId,
}: ActivationDrawerSchema) => {
  const tenantClient: Tenant = useSelector((state: any) => state.tenant);
  const staticPricePerActivation = tenantClient.activationConfig.defaultActivationPrice;
  const taxes = tenantClient.activationConfig.defaultTaxRate;
  const moneySymbol = tenantClient.currency.symbol;
  const [errors, setErrors] = useState<ActivationDrawerErrorSchema>({
    activation_terms_conditions: '',
  });
  const [terms, setTerms] = useState(false);

  const getTotalByBrand = (brand: ActivationTableRowData) => {
    let localTotal = 0;

    for (const store of brand.stores) {
      localTotal +=
        store.numberOfCollaborators *
        staticPricePerActivation *
        store.dates.length;
    }

    return localTotal;
  };

  const getTotalByDatasource = () => {
    let localTotal = 0;

    for (const brand of dataSource) {
      for (const store of brand.stores) {
        localTotal +=
          store.numberOfCollaborators *
          staticPricePerActivation *
          store.dates.length;
      }
    }

    return localTotal;
  };

  const getDataFormatted = (): ActivationCreate => {
    return {
      activationTypeId: activationTypeId,
      subtotalAmount: parseFloat(getTotalByDatasource().toFixed(2)),
      taxRate: taxes,
      totalAmount: parseFloat(
        (getTotalByDatasource() * (1 + taxes)).toFixed(2)
      ),
      activations: dataSource.map((brandRow) => {
        return {
          brandId: brandRow.brandId,
          stores: brandRow.stores.map((storeRow) => {
            return {
              storeId: storeRow.id,
              dates: storeRow.dates,
              numberOfCollaborators: storeRow.numberOfCollaborators,
              totalAmount: parseFloat(
                (
                  storeRow.numberOfCollaborators *
                  storeRow.dates.length *
                  staticPricePerActivation
                ).toFixed(2)
              ),
            };
          }),
        };
      }),
    };
  };

  return (
    <>
      {isDrawerOpen && (
        <>
          <div className="rsm-fixed rsm-inset-0 rsm-opacity-50 rsm-z-40 rsm-bg-gray-700 modal-provider__main request_activation_drawer_animation"></div>
          <div className="rsm-fixed rsm-inset-0  rsm-flex rsm-justify-center rsm-items-center rsm-z-40 lateralBar">
            <div className="rsm-bg-white rsm-rounded-md rsm-overflow-auto rsm-max-h-full rsm-w-full  rsm-opactiy-100 rsm-shadow-lg rsm-z-50 rsm-border rsm-border-gray-200 rsm-flex rsm-flex-col">
              <EndHeader>
                <div className="general_model_top_content__turn_detail_modal">
                  <CloseIcon
                    className="general_modal_close"
                    onClick={() => {
                      if (!loading) {
                        closeDrawer();
                      }
                    }}
                  />
                </div>
                <div className="general_container_end">
                  <div className="div_containe">
                    <table className="activation_drawer_table">
                      {dataSource.map((row) => {
                        return (
                          <tr key={`tr_request_${row.brandId}`}>
                            <td>{row.requestNumber}</td>
                            <td className="activation_drawer_td_money">
                              {moneySymbol + ' '}
                              {getTotalByBrand(row).toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                      <tr key={`tr_subtotal`}>
                        <td>Subtotal</td>
                        <td className="activation_drawer_td_money">
                          {moneySymbol + ' '}
                          {getTotalByDatasource().toFixed(2)}
                        </td>
                      </tr>
                      <tr key={`tr_taxes`}>
                        <td>Impuestos</td>
                        <td className="activation_drawer_td_money">
                          {(taxes * 100).toFixed(0)}%
                        </td>
                      </tr>
                      <tr key={`tr_total`}>
                        <td style={{ fontWeight: '600' }}>Total</td>
                        <td
                          className="activation_drawer_td_money"
                          style={{ fontWeight: '600' }}
                        >
                          {moneySymbol + ' '}
                          {(getTotalByDatasource() * (taxes + 1)).toFixed(2)}
                        </td>
                      </tr>
                    </table>

                    <div className="request_activation_container_terms_checkbox">
                      <Checkbox
                        errors={errors}
                        label={
                          <>
                            <span className="terms_conditions__text">
                              Acepto que para efecturar la activación debo usar
                              la app que me indica Wong
                            </span>
                          </>
                        }
                        name="activation_terms_conditions"
                        checked={terms}
                        onChange={(newValue) => {
                          setTerms(newValue);
                        }}
                      />
                    </div>
                    <div className="activaton_drawer_content__button">
                      <TenantButton
                        text={
                          loading
                            ? 'Cargando...'
                            : dataSource.length === 1
                            ? 'SOLICITAR ACTIVACIÓN'
                            : 'SOLICITAR ACTIVACIONES'
                        }
                        action={() => {
                          action(getDataFormatted());
                        }}
                        fullwidth
                        disabled={!terms || loading}
                      />
                    </div>
                  </div>
                </div>
              </EndHeader>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ActivationDrawer;

import React from 'react';
import { Modal } from "react-responsive-modal";

import TenantButton from '../TenantButton/TenantButton';
import './TenantAlertMessage.scss';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface TenantAlertMessageSchema {
  isModalOpen: boolean;
  closeModal: () => void;
  isErrorMessage?: boolean;
  title: string;
  content: string | React.ReactNode;
  action?: () => void;
}

function TenantAlertMessage({
  isModalOpen,
  closeModal,
  isErrorMessage = false,
  title,
  content,
  action,
}: TenantAlertMessageSchema) {
  return (
    <Modal
      modalId="tenant-alert-message"
      open={isModalOpen}
      classNames={{
        overlay: 'customOverlay',
        modal: 'modal_tenant_alert_message',
      }}
      onClose={closeModal}
      showCloseIcon={false}
    >
      <div className="modal_tenant_alert_message_container">
        <div className="modal_tenant_alert_message_title">
          {isErrorMessage ? (
            <ErrorOutlineIcon className="modal_tenant_alert_message_svg" />
          ) : (
            <CheckCircleOutlineIcon className="modal_tenant_alert_message_svg" />
          )}{' '}
          <span className="modal_tenant_alert_message_title__text">
            {title}
          </span>
        </div>
        <div className="modal_tenant_alert_message_text">{content}</div>
        <TenantButton
          text="Aceptar"
          action={async () => {
            if (action) {
              await action();
            } else {
              closeModal();
            }
          }}
          fullwidth
        />
      </div>
    </Modal>
  );
}

export default TenantAlertMessage;

import './AlterTenantButton.scss';

interface AlterTenantButtonInterface {
  className?: string;
  disabled?: boolean;
  text: string | React.ReactNode;
  fullwidth?: boolean;
  action: () => void;
}

const AlterTenantButton = ({
  className = '',
  disabled = false,
  text,
  fullwidth = false,
  action,
}: AlterTenantButtonInterface) => {
  return (
    <button
      className={`alter_tenant__btn${className ? ` ${className}` : ''}${
        disabled ? ' alter_tenant__btn_disabled' : ''
      }${fullwidth ? ' alter_tenant__btn_w100' : ''}`}
      onClick={() => {
        action();
      }}
    >
      {text}
    </button>
  );
};

export default AlterTenantButton;

import { Chip, Container, TextField } from "@material-ui/core";

import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

import './Select.scss';
import {useEffect, useState} from "react";

export interface DataSelectInterface {
  text: string;
  value: string;
  idState?: number;
  idCountry?: number;
};

interface SelectInterface {
  disabled?: boolean;
  className?: string;
  data: DataSelectInterface[];
  errors?: any;
  errorClassName?: string;
  errorWithMessage?: boolean;
  label: string;
  labelColor?: string;
  name: string;
  onChange?: (event: any, value?: DataSelectInterface | null) => void;
  placeholder?: string;
  required?: boolean;
  value?: any;
  variant?: "outlined" | "standard" | "filled" | undefined;
  defaultValue?: any;
};

const MultiSelect = ({
  disabled = false,
  className = '',
  data,
  errors = null,
  errorClassName = '',
  errorWithMessage=false,
  label,
  labelColor,
  name,
  onChange = (e: any, value: any) => {},
  placeholder = '',
  required = true,
  value,
  defaultValue,
  variant = 'outlined',
  ...rest
}: SelectInterface) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [inputKey, setInputKey] = useState(Date.now());

  if (className) className = ` ${className}`;

  // if (typeof value === 'number') {
  //   value = `${value}`;
  // } else {
  //   if (value && value.text) value = value.value;
  // }

  return (
   <Container maxWidth="sm">
      <p className="textfield__label" style={{ color: labelColor }}>{`${label}${required ? '*' : ''}:`}</p>
      <Autocomplete
        disabled={disabled}
        defaultValue={defaultValue}
        className="autocomplete__main"
        id={name}
        value={value}
        options={data}
        multiple
        getOptionLabel={(option) => option.text}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.text}
          </li>
        )}
        fullWidth
        onChange={(e, data) => onChange(data)}
        renderTags={(value: readonly string[], getTagProps) =>{
          return <span>{` ${value.length} ${placeholder} seleccionado`}</span>;
        }}
        renderInput={(params) => {
          return (<TextField
            {...params}
            className={`textfield__main ${className}`}
            required={required}
            name={name}
            variant={variant}
            value={value ? `${value.length} seleccionados` : ''}
            {...rest}
          />)
        }}
        popupIcon={<ExpandMoreIcon />}
      />
     {
       errorWithMessage ? (
           (errors && errors[name]) && <ErrorMessage className={errorClassName} message={errors[name]}/>
       ) : (
           (errors && errors[name]) && <ErrorMessage className={errorClassName} />
       )
     }

   </Container>
  );
};

export default MultiSelect;

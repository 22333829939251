import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import GuestRoute from "./components/GuestRoute";
import PrivateRoute from "./components/PrivateRoute";
import Home from "../scenes/Home/Home";
import RequestActivation from "../scenes/RequestActivation/index";
import HistoryActivation from "../scenes/History/index";
import TurnActivation from "../scenes/TurnActivation/index";
import ActivationDetail from "../scenes/ActivationDetail";
import ReportView from "../scenes/Report/index";
import Login from "../scenes/Login/Login";
import NotFound from "../scenes/NotFound/NotFound";
import TenantInfo from "../scenes/TenantInfo/TenantInfo";
import { ENV, MenuType } from "../config/Config";
import DynamicViewer from "../scenes/DynamicViewer/DynamicViewer";
import { User } from "../models/User";
import { useSelector } from "react-redux";

export default function AppRoutes() {
  let protectedFirstRoute = "/home";
  const user: User = useSelector((state: any) => state.auth);
  const menus = user.allowedMenus.filter((menu) => {
    return [
      MenuType.STANDARD_MENU as string,
      MenuType.IFRAME_VIEWER as string,
    ].includes(menu.menuTypeId);
  });

  if (menus.length > 0) {
    const firstMenu = menus[0];
    if (firstMenu.redirectLink) {
      if (firstMenu.menuTypeId === MenuType.STANDARD_MENU) {
        protectedFirstRoute = firstMenu.redirectLink;
      }
      if (firstMenu.menuTypeId === MenuType.IFRAME_VIEWER) {
        protectedFirstRoute = `/dynamic-viewer/${firstMenu.code}`;
      }
    }
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to={protectedFirstRoute} />} />
      <Route path="/login" element={<GuestRoute outlet={<Login />} />} />
      <Route path="/home" element={<PrivateRoute outlet={<Home />} />} />

      <Route
        path="/dynamic-viewer/:key"
        element={
          <PrivateRoute
            outlet={<DynamicViewer />}
            type={MenuType.IFRAME_VIEWER}
          />
        }
      />
      <Route
        path="/history-activation/request-activation"
        element={
          <PrivateRoute
            outlet={<RequestActivation />}
            type={MenuType.STANDARD_MENU}
          />
        }
      />
      <Route
        path="/history-activation"
        element={
          <PrivateRoute
            outlet={<HistoryActivation />}
            type={MenuType.STANDARD_MENU}
          />
        }
      />
      <Route
        path="/turn-activation"
        element={
          <PrivateRoute
            outlet={<TurnActivation />}
            type={MenuType.STANDARD_MENU}
          />
        }
      />
      <Route
        path="/history-activation/detail/:activationId"
        element={
          <PrivateRoute
            outlet={<ActivationDetail />}
            type={MenuType.STANDARD_MENU}
          />
        }
      />
      <Route
        path="/report"
        element={
          <PrivateRoute outlet={<ReportView />} type={MenuType.STANDARD_MENU} />
        }
      />
      {ENV === "development" && (
        <Route path="/tenant-info" element={<TenantInfo />} />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

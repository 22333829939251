import { ApiErrorData, ApiResponse } from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {
  ActivationCreate,
  PaginateActivation,
} from "./interfaces/ActivationInterface";
import { FunctionsHelper } from "../utils/FunctionsHelper";

const ActivationService = {
  getLastRequestNumber: async (): Promise<any> => {
    try {
      const response = await MainBackendAxios.get(
        `/activation/get-last-activation-request-number`
      );
      return {
        success: true,
        status: response.status,
        data: response.data?.data,
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  getById: async (id: string): Promise<any> => {
    try {
      const response = await MainBackendAxios.get(
        `/activation/find-by-id/${id}`
      );

      return {
        success: true,
        status: response.status,
        data: response.data?.data,
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  getAllPaginate: async (dataPaginate: PaginateActivation): Promise<any> => {
    try {
      const response = await MainBackendAxios.post(
        `/activation/get-all-with-paginate`,
        dataPaginate
      );

      return {
        success: true,
        status: response.status,
        data: response.data?.data,
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  create: async (dataActivation: ActivationCreate): Promise<any> => {
    try {
      const response = await MainBackendAxios.post(
        `/activation/create`,
        dataActivation
      );
      let user: any | null = null;
      return {
        success: true,
        status: response.status,
        data: response.data,
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  getPaymentByActivationId: async (id: string): Promise<any> => {
    try {
      const response = await MainBackendAxios.get(
        `/activation-payment/find-by-activation/${id}`
      );

      return {
        success: true,
        status: response.status,
        data: response.data?.data,
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  uploadPurchaseOrder: async (
    activationId: string,
    file: File
  ): Promise<any> => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await MainBackendAxios.post(
        `/activation-file/upload-purchase-order/${activationId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return {
        success: true,
        status: response.status,
        data: response.data?.data,
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
};

export default ActivationService;

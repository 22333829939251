import React from 'react';
import { toast } from 'react-toastify';

import TenantButton from '../../../components/TenantButton/TenantButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from '../../../components/Molecules/Datepicker/Datepicker';
import moment from 'moment/moment';
import Accordion from '../../../components/Atoms/Accordion/Accordion';
import { DataInterface } from '../../../components/Atoms/Select/Select';

interface HistoryFilterOptionSchema {
  isFilterOpen: boolean;
  setIsFilterOpen: (value: boolean) => void;
  grayColor?: boolean;
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  currentStartDate: string;
  currentEndDate: string;
  statusesSelected: string[];
  setStatusesSelected: (value: string[]) => void;
  currentStatusesSelected: string[];
  submit: (startDate: string, endDate: string, statuses: string[]) => void;
  statuses: DataInterface[];
}

const HistoryFilterOption = ({
  isFilterOpen,
  setIsFilterOpen,
  grayColor = false,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  currentStartDate,
  currentEndDate,
  statusesSelected,
  setStatusesSelected,
  currentStatusesSelected,
  submit,
  statuses,
}: HistoryFilterOptionSchema): JSX.Element => {
  const actionClickFilterContainer = () => {
    if (!isFilterOpen) {
      //Asignar los valores actuales
      setStartDate(moment(currentStartDate, 'YYYY-MM-DD').toDate());
      setEndDate(moment(currentEndDate, 'YYYY-MM-DD').toDate());
    }
    setStatusesSelected(currentStatusesSelected);
    setIsFilterOpen(!isFilterOpen);
  };

  const handleSingleStatusChange = (e: any, statusValue: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const updatedSelected = [...statusesSelected, statusValue];
      setStatusesSelected(updatedSelected);
    } else {
      const updatedSelected = statusesSelected.filter(
        (status) => status !== statusValue
      );
      setStatusesSelected(updatedSelected);
    }
  };

  const cancelOnClick = () => {
    setIsFilterOpen(false);
  };

  const startOnChange = (date: Date | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  const endOnChange = (date: Date | null) => {
    if (date) {
      setEndDate(date);
    }
  };

  const submitOnClick = async () => {
    const startDateParam = moment(startDate).format('YYYY-MM-DD');
    const endDateParam = moment(endDate).format('YYYY-MM-DD');

    if (moment(startDate).isAfter(moment(endDate))) {
      toast.error(
        'La primera fecha del rango no debe de ser mayor a la fecha final',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      return;
    }

    const statusesParam = [...statusesSelected];

    await submit(startDateParam, endDateParam, statusesParam);
    setIsFilterOpen(false);
  };

  return (
    <>
      {/* Opener Button */}
      <div
        className="history-filter-button"
        onClick={actionClickFilterContainer}
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1824_87)">
            <path d="M14.0001 24C13.7837 24 13.5732 23.9298 13.4001 23.8L9.40008 20.8C9.27588 20.7069 9.17508 20.5861 9.10565 20.4472C9.03622 20.3084 9.00008 20.1552 9.00008 20V14.38L1.98408 6.487C1.4858 5.92488 1.16046 5.23082 1.04716 4.48823C0.933858 3.74565 1.03742 2.98615 1.3454 2.30101C1.65338 1.61588 2.15267 1.03426 2.78326 0.626066C3.41385 0.217873 4.1489 0.000474419 4.90008 0L19.1001 0C19.8512 0.000881051 20.5861 0.218639 21.2164 0.627107C21.8467 1.03557 22.3457 1.61737 22.6534 2.30259C22.961 2.9878 23.0642 3.74727 22.9507 4.48975C22.8371 5.23222 22.5115 5.9261 22.0131 6.488L15.0001 14.38V23C15.0001 23.2652 14.8947 23.5196 14.7072 23.7071C14.5196 23.8946 14.2653 24 14.0001 24ZM11.0001 19.5L13.0001 21V14C13.0003 13.7552 13.0903 13.5189 13.2531 13.336L20.5211 5.159C20.7634 4.88508 20.9216 4.54704 20.9765 4.18545C21.0315 3.82387 20.9809 3.4541 20.8308 3.12056C20.6808 2.78701 20.4377 2.50386 20.1307 2.30509C19.8237 2.10632 19.4658 2.00038 19.1001 2H4.90008C4.53453 2.00055 4.1769 2.10655 3.87008 2.30527C3.56326 2.50399 3.32029 2.78699 3.17029 3.12035C3.02029 3.45371 2.96963 3.82326 3.02441 4.18468C3.07918 4.5461 3.23705 4.88405 3.47908 5.158L10.7481 13.336C10.9105 13.519 11.0002 13.7553 11.0001 14V19.5Z" />
          </g>
          <defs>
            <clipPath id="clip0_1824_87">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <span>Filtro</span>
      </div>

      {/* Container */}
      <div
        id="filter-menu"
        className={`filter-menu${isFilterOpen ? ' filter-menu--open' : ''}${
          !actionClickFilterContainer ? ' filter-menu--no-handle' : ''
        }`}
      >
        <div className="label-filter-menu-item">
          <p>Filtro</p>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={cancelOnClick}
          >
            <path d="M13 1C12.8124 0.81253 12.5581 0.707214 12.293 0.707214C12.0278 0.707214 11.7735 0.81253 11.586 1L6.99997 5.586L2.41397 1C2.22644 0.81253 1.97213 0.707214 1.70697 0.707214C1.44181 0.707214 1.1875 0.81253 0.99997 1C0.812499 1.18753 0.707184 1.44184 0.707184 1.707C0.707184 1.97217 0.812499 2.22647 0.99997 2.414L5.58597 7L0.99997 11.586C0.812499 11.7735 0.707184 12.0278 0.707184 12.293C0.707184 12.5582 0.812499 12.8125 0.99997 13C1.1875 13.1875 1.44181 13.2928 1.70697 13.2928C1.97213 13.2928 2.22644 13.1875 2.41397 13L6.99997 8.414L11.586 13C11.7735 13.1875 12.0278 13.2928 12.293 13.2928C12.5581 13.2928 12.8124 13.1875 13 13C13.1874 12.8125 13.2928 12.5582 13.2928 12.293C13.2928 12.0278 13.1874 11.7735 13 11.586L8.41397 7L13 2.414C13.1874 2.22647 13.2928 1.97217 13.2928 1.707C13.2928 1.44184 13.1874 1.18753 13 1Z" />
          </svg>
        </div>

        {/* Filter: dates */}
        <div className="filter-menu-item" key="filter-dates">
          <Accordion
            startExpanded
            items={[
              {
                label: 'Fecha',
                children: (
                  <>
                    <div className="dates__main">
                      <div className="dates__col">
                        <DatePicker
                          label=""
                          value={startDate}
                          onChange={startOnChange}
                        />
                      </div>
                      <div className="dates__col">
                        <DatePicker
                          label=""
                          value={endDate}
                          onChange={endOnChange}
                        />
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>

        {/* Filter: Statuses */}
        <div className="filter-menu-item" key="filter-status">
          <Accordion
            items={[
              {
                label: 'Estados',
                children: (
                  <>
                    {statuses.map((statusRow) => (
                      <div
                        className="checkbox__main"
                        key={`container____${statusRow.value}`}
                      >
                        <FormControlLabel
                          key={statusRow.value}
                          control={
                            <Checkbox
                              checked={statusesSelected.includes(
                                statusRow.value
                              )}
                              onChange={(e) =>
                                handleSingleStatusChange(e, statusRow.value)
                              }
                              name={`input__state_dinamic_${statusRow.value}`}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label={
                            <p
                              className="text__main"
                              style={{ fontSize: '14px', marginLeft: '10px' }}
                            >
                              {statusRow.text}
                            </p>
                          }
                          className="label-contain"
                          labelPlacement="start"
                          style={{ width: '100%' }}
                        />
                      </div>
                    ))}
                  </>
                ),
              },
            ]}
          />
        </div>

        {/* Botones: submit */}
        <div className="history_activation_filter-footer">
          <TenantButton
            className="history_activation_filter_button history_activation_filter_button__variant"
            text="Cancelar"
            action={cancelOnClick}
          />
          <TenantButton
            className="history_activation_filter_button"
            text="Aplicar"
            action={submitOnClick}
          />
        </div>
      </div>
    </>
  );
};

export default HistoryFilterOption;

import { useTranslation } from "react-i18next";

import './ErrorMessage.scss';

interface ErrorMessageInterface {
  message?: string;
  className?: string;
  children?: React.ReactNode;
};

const ErrorMessage = ({
  message = '',
  className,
  children = null,
  ...rest
}: ErrorMessageInterface) => {
  const { t } = useTranslation();

  return (
    <div className="error_message__main">
      <span className={`error_message ${className}`}>
        {message ? message : t('components.error_message.required_field')}
      </span>
    </div>
  );
};

export default ErrorMessage;
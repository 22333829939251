import { useState } from "react";
import { Container, FormControlLabel } from "@material-ui/core";
import { Checkbox as MaterialCheckBox } from '@material-ui/core';
import ErrorMessage from "../ErrorMessage/ErrorMessage";

import './Checkbox.scss';

interface CheckboxInterface {
  className?: string;
  checked?: boolean;
  errors?: any;
  errorClassName?: string;
  label: string | React.ReactNode;
  name: string;
  onChange: (event : any) => void;
  register?: any;
};

const Checkbox = ({
  className = '',
  checked = false,
  errors = null,
  errorClassName = '',
  label,
  name,
  onChange,
  register = null,
  ...rest
}: CheckboxInterface) => {
  const [isChecked, setIsChecked] = useState(checked);

  const checkBoxOnChange = (e: any) => {
    setIsChecked(e.target.checked);
    onChange(e.target.checked);
  };

  return (
   <Container className="checkbox__container" maxWidth="sm">
      <FormControlLabel
        className="form-control__main"
        name={name}
        id={name}
        checked={checked? checked : isChecked}
        onChange={checkBoxOnChange}
        control={
          <MaterialCheckBox />
        }
        label={label}
        {...register}
        {...rest}
      />
      {errors && errors[name] && <ErrorMessage className={errorClassName} />}
   </Container>
  );
};

export default Checkbox;

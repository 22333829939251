import React, { useState } from "react";
import ReactDOM from "react-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./Modal.scss";
import TenantButton from "../TenantButton/TenantButton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

interface TenantAlertMessageVariantSchema {
  isModalOpen: boolean;
  closeModal: () => void;
  isErrorMessage?: boolean;
  title?: string;
  content?: string | React.ReactNode;
  action?: () => void;
  textAction?: string;
}

const ModalTenant = ({
  isModalOpen,
  closeModal,
  isErrorMessage = false,
  title,
  content,
  action,
  textAction = "Aceptar",
}: TenantAlertMessageVariantSchema) => {
  return (
    <div>
      <Modal open={isModalOpen} onClose={closeModal} center classNames={{
          overlay: 'customOverlay',
          modal: 'modal_tenant_alert_variant_message',
        }}>
        <div className="modal_tenant_alert_variant_message_container">
          <div className="modal_tenant_alert_variant_message_title">
            {isErrorMessage ? (
              <ErrorOutlineIcon className="modal_tenant_alert_variant_message_svg" />
            ) : (
              <CheckCircleOutlineIcon className="modal_tenant_alert_variant_message_svg" />
            )}{" "}
            <span className="modal_tenant_alert_variant_message_title__text">
              {title}
            </span>
          </div>
          <div className="modal_tenant_alert_variant_message_text">{content}</div>

          <div className="modal_tenant_alert_variant_button_container">
            <TenantButton
              className="modal_tenant_alert_variant_button_container__variant"
              text="Cancelar"
              action={async () => {
                closeModal();
              }}
              fullwidth
            />

            <TenantButton
              text={textAction}
              action={async () => {
                if (action) {
                  await action();
                } else {
                  closeModal();
                }
              }}
              fullwidth
            />
          </div>
        </div>

      </Modal>
    </div>
  );
};

export default ModalTenant;

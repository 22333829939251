import "./styles.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../Header/Header";
import { User } from "../../models/User";
import Container from "../../components/TenantContainer/Container";

interface TableRow {
  [key: string]: string;
}

const ReportsView = (): JSX.Element => {
  const user: User = useSelector((state: any) => state.user);
  const [powerBiUrl, setPowerBiUrl] = useState("");
  const [titleBiUrl, setTitleBiUrl] = useState("");

  const heightOffset = 73;
  const vh = window.innerHeight;
  const vw = window.innerWidth;

  useEffect(() => {
    getReport();
  }, []);

  const getReport = async () => {
    switch (user?.email) {
      case "adriana.monzon@pe.nestle.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiNWIxM2U0YmQtOTQ4NS00M2Y3LTg2YmUtMzdkNWIzM2U2YTQzIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Gerber Asia 2024");

        break;
      case "alessia.brambilla@pe.nestle.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiM2MwYWE2MTktNmI4OC00NjA5LWFlYjgtZmI5ZmY4NTIyYzQ1IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Helados Nestle Asia 2024");

        break;
      case "andrea.vergara@accorporativo.pe":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiMjI3YTBkYjEtOGE4OC00YjZmLTg0ZDItMTQ4Mjc3Y2ZkYzQ3IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Inca Kola Asia 2024");

        break;
      case "angie.tocas@pepsico.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiZTFmMmJlODQtMGQyMC00OTZiLTk1NzYtNGQ0MDhjYzllNDg5IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Pepsico Asia 2024");

        break;
      case "angie.tocas@pepsico.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiZTFmMmJlODQtMGQyMC00OTZiLTk1NzYtNGQ0MDhjYzllNDg5IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Pepsico Moderno Feb 2024");

        break;
      case "aojeda@lacoste.com ":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiNDUxNjUzZWEtYmM3Yy00OTc2LTg2OTItYjJkM2YwMjVlMWUyIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Lacoste");

        break;
      case "ariana.garcia@essity.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiYTQ0ZjBkZmEtMTUxNy00MTBlLThkYTQtZWRhMzY3ODgwOWM4IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Nosotras Asia 2024");

        break;
      case "azelada@cartaviorumco.pe":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiNzEzN2MzMzAtZWEzOC00YWJlLWE2YTEtZjJhNWQxYjU3ODhlIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Cartavio Asia 2024");

        break;
      case "bejarano.m@pg.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiNmNlZWUxYTUtMzY0OC00MmU3LWIyNGEtMjBkOGQzN2I2OWQwIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("H&S Asia 2024");

        break;
      case "cabe@ecoprana.com.pe":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiMTZkZjI3OWYtMTgwMy00YWJiLTgzZTUtNGNlZDE3N2VlNGM2IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Bambo Nature Asia 2024");

        break;
      case "camila.bondy@gmail.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiMDY2OTc0NGUtZTY3MS00ZGM4LTlmZDEtYWNkZmFlNGVhOGE2IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Crema y Nata Asia 2024");

        break;
      case "carmen.chillitupa@pe.nestle.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiOGViZjMyZGItZGIyZi00NmRlLWJjMWItNGM4NWRjMmVkMTAzIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Cafes Nestle");

        break;
      case "carolina.andrade@panuts.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiNmM4YmUyZjktMTUxNi00YWM5LTkyMzEtNmU3Nzc2YTRjZWFlIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Lavazza Asia 2024");

        break;
      case "cesar.estrada@pe.nestle.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiNmM4YmUyZjktMTUxNi00YWM5LTkyMzEtNmU3Nzc2YTRjZWFlIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Maggi 2024");

        break;
      case "claudia.vallenas@powernature.pe":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiY2Q4YjI5YzgtZDQwOC00MGE2LTg0N2YtYjBkNzM4MWMxMDVjIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Lakanto Asia 2024");

        break;
      case "davis.d.16@pg.com ":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiNzNkZGExODItMTBiNy00Mjk2LWE4ZTUtMmE4NjIxODkwM2NjIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Oral B");

        break;
      case "egsalazar@chocolates.pe":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiM2IxN2M5MjQtZjA3OC00NTJkLTgwOGItZWM3ODBkNGU4ZjM1IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Granuts Asia 2024");

        break;
      case "egsalazar@chocolates.pe":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiMjU4ZGYyNDYtZWI0Ni00MTdkLTljODEtOTcwNWMzMjM0M2Y4IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Picaras Asia 2024");

        break;
      case "ena.penaherrera@edgewell.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiYzZmYjg1ODItYzBkNC00Yzg0LTgwM2QtNTI5OWIyN2QwMTAyIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Hawaiian Tropic Asia 2024");

        break;
      case "flavia.ugaz@heineken.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiYjc2ZmIzMTQtZDM0Yi00MjBmLTg4YmEtN2MwNTkwY2MzMTY3IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Heineken Asia 2024");

        break;
      case "gabriela.valera@beiersdorf.com ":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiZjE0NWIyZWItYjMwOS00NGI4LWE1OTYtNTExYTJmN2U1YTU1IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Nivea");

        break;
      case "gabriela.valera@beiersdorf.com ":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiNzgyYjE1YTctOTlhNC00YWM4LTkzZmItMjZkNTU2Yzg5ZjY2IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Nivea Luminous");

        break;
      case "gabriela.valera@beiersdorf.com ":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiNzQ2OGRlZWYtOTU1OC00OTYxLTkyN2ItNGQ5ZGY3NjcxOTk0IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Nivea Sun");

        break;
      case "geraldine.mallqui@campari.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiZDQ2YTg5OWQtMGFkZC00YzllLWE0NzAtMjBmMWNmNzVhNmQ2IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Campari Asia 2024");

        break;
      case "ggalecio@terra-fertil.com ":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiNTc5ZmQ0ODUtM2E5Ni00MjgyLWIyMmEtN2E4NTg1ODBkMmUzIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Nature´s Heart Asia 2024");

        break;
      case "ggalecio@terra-fertil.com ":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiYmVmYjExMmQtNjljMC00MzgwLTg4ZWItMjdjNDI1NjFmYjliIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Nature´s Heart Lima 2024");

        break;
      case "guillermo.vega@britt.com ":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiNGJmM2EzMTctMDhhZi00YjVjLTliNjEtMGI5ZmEwMWM3NDAzIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Britt Asia 2024");

        break;
      case "ipendavis@santaelena.com.pe ":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiMjBjZGFlMzItYzBjYS00M2QzLThjNWItNDEyNGYwYjFlNmZkIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Avinka / Artisan Asia 2024");

        break;
      case "jchavez@bacardi.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiYWRiYTI4OGYtZWZmYi00YWM4LWE1M2EtNWUzZDQ3Nzc3M2Y3IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Bacardi Asia 2024");

        break;
      case "jjeri@inkacrops.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiM2Y0YTA2YzAtMDYzMS00ZGMzLWEyMjQtMzdlZTQwNDE1NzZkIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Inkachips Asia 2024");

        break;
      case "kamila.jaramillo@clorox.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiYmE2MDY3YjctODM2ZC00NTZhLTk3MjYtMWZlYTVlODc1YTdmIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Poett Asia 2024");

        break;
      case "kevin.neyra@yichang.pe ":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiZGMxYmJlYWYtYzM4ZS00ZTk5LWJlMTEtZWVmMzE0NjM3ZWM2IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Haagen Dazs Asia 2024");

        break;
      case "laura.leon@yichang.pe":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiN2JiMjkwNzAtNTUxNS00ZTlmLThmZjMtM2ZlZWNiODJhNWUwIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Florida Asia 2024");

        break;
      case "lorena.arbulu.pe@csoluciones.pe":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiN2JiMjkwNzAtNTUxNS00ZTlmLThmZjMtM2ZlZWNiODJhNWUwIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Splenda Asia 2024");

        break;
      case "lorena.arbulu.pe@csoluciones.pe":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiZDE2ZDk1MjEtMzdkMi00NmY4LTk2NGUtODJjNTM0ZGFkNWQ3IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Sporade Asia 2024");

        break;
      case "lrojas01@kenvue.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiOTNlMDkwMWUtZmFiMi00ZWNiLTk3YWUtYTVhNGIyNTZjZTY1IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Neutrogena Asia 2024");

        break;
      case "maira.gutierrez0497@gmail.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiZTQ2ODk1ZDctMGMwZi00M2M3LWIxMmUtMDY4Njc5M2U0OGRlIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Bimbo Nachos Asia 2024");

        break;
      case "maira.gutierrez0497@gmail.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiN2NmY2YyMDItYWViZi00OTlmLTljMjgtNWU2YTVlMDE3ZDY2IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Bimbo Vital Asia 2024");

        break;
      case "maira.gutierrez0497@gmail.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiNDBkMzRjZDMtMTc0ZS00NTI1LWFlOTctMzUwY2E2NDEzZDhhIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Takis Asia 2024");

        break;
      case "marco.atencio@llapan.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiZjBjZGZlYTMtYTJiNC00M2RhLTkwN2YtMWU0YjEyYjU0Y2NmIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Tiyapuy Asia 2024");

        break;
      case "mariana.manriquede@ab-inbev.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiM2Q4ZGVlMjYtYjU4NC00NmE0LTk0MWEtZTA1NmJlZjdkNDI4IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Corona Asia 2024");

        break;
      case "mariana.manriquede@ab-inbev.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiYTYyY2Y4ZGUtYjI2ZS00ZGQ4LWEyYzgtMmFiMjQ0MzliYWRmIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Corona Drinks Asia 2024");

        break;
      case "mariana.manriquede@ab-inbev.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiZmMwMzkxNTgtYTk4NC00NDJkLTg1YzctOWY2ZWVhYzhhNzhkIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Stella Artois Asia 2024");

        break;
      case "natalia.soto@pe.nestle.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiZjVjZjkxYmEtNDFmOC00ZjMzLWExNTgtZDVkNmI2NjZmYmY0IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Milo");

        break;
      case "r.barrios@mitsui.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiMjlhN2U2YmMtNGU4Mi00NmI4LTg3NjItNTNmYmZjYWZhODZlIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Santa Priscila Asia 2024");

        break;
      case "rmatta@ricocan.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiZjdiYTljMWQtNDYzNS00YTQ0LTg5MDctODJhM2U0ZWQ4MGI2IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Ricocan Asia 2024");

        break;
      case "silvia.sacovertiz@cencosud.com.pe":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiMTI3NGMyNmItODkwZS00YmEzLTg5NTUtZjcwNGRjOTA5OTg1IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Cusine & Co Asia 2024");

        break;
      case "valeria.zuniga@unilever.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiZTUxM2FlZjUtNDRmOS00OTJmLTg4OWItMzVhYzgxZTI4NWYzIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("CIF Asia 2024");

        break;
      case "valeria.zuniga@unilever.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiN2Q4Mzg1MTItN2I1NS00OTAxLTg4NGMtMWRkMDJhMjZiY2FjIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Ponds Asia 2024");

        break;
      case "valeria.zuniga@unilever.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiNWQxYzk3YWQtY2I3OS00MzBiLTlmM2UtYTA3OTc3ODM5MzA1IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Sedal Asia 2024");

        break;
      case "valeria.zuniga@unilever.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiZDA5ZjQyZjgtZThmZi00OGI1LWE4ZmItOTFiZmQ4NDdlMTFiIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Unilever (Axe, Dove, Rexona) Asia 2024");

        break;
      case "yalva@deprodeca.com.pe":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiYWQ5YWNmNTctOGIzMy00MWU3LWJiYzYtM2Q1NDZjYTAxNmI0IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Gloria Asia 2024");

        break;
      case "yalva@deprodeca.com.pe":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiYjExYWEwNGUtY2U3MC00ZDhjLWI0NDEtMDRkYzFkZmI2NTI1IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Gloria Slim Asia 2024");

        break;
      case "manuelvallejos@touchlatam.com":
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiYjIzYWZlMWYtYjgyMS00ODIzLTlmOTEtYTI0ZjdjMGQ5NmZmIiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Reporte Asia 2024");
        break;

      default:
        setPowerBiUrl(
          "https://app.powerbi.com/view?r=eyJrIjoiYjExYWEwNGUtY2U3MC00ZDhjLWI0NDEtMDRkYzFkZmI2NTI1IiwidCI6ImE2YTMwMGJiLWE0ZGYtNDE4Zi1hZGQ5LTc1M2RkOWY4NTI5ZiIsImMiOjR9"
        );
        setTitleBiUrl("Report Default 2024");
        break;
    }
  };

  return (
    <>
      <Header>
      {powerBiUrl && titleBiUrl && (
              <iframe
                src={powerBiUrl}
                title={titleBiUrl}
                frameBorder={0}
                style={{
                  width: `${vw}px`,
                  height: `${vh - heightOffset}px`,
                }}
              />
            )}
      </Header>
    </>
  );
};

export default ReportsView;

import MainBackendAxios from "../utils/MainBackendAxios";
import { FunctionsHelper } from "../utils/FunctionsHelper";

const ActivationTypeService = {
    get: async (): Promise<any> => {

        try {
            const response = await MainBackendAxios.get(`/activation-type/get-all`);
            return {
                success: true,
                data: response?.data?.data,
            };
        } catch (err) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getById: async (id: string): Promise<any> => {

        try {
            const response = await MainBackendAxios.get(`/activation-type/find-by-id/${id}`);
            let user: any | null = null;

            return {
                success: true,
                data: user,
            };
        } catch (err) {
            return FunctionsHelper.getErrorFormat(err);
        }
    }
};

export default ActivationTypeService;
import React, { Fragment } from 'react';
import { TextField } from '@material-ui/core';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';

import './TextInput.scss';
interface TextInputInterface {
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  error?: boolean;
  errors?: any;
  errorClassName?: string;
  inputProps?: any;
  inputPropsAdornments?: any;
  isValid?: boolean;
  label?: string;
  labelColor?: string;
  large?: boolean;
  multiline?: boolean;
  name: string;
  onChange?: (event: any) => void;
  placeholder?: string;
  register?: any;
  rows?: number;
  type?: string;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
  value?: any;
  onEnterAction?: () => void;
}

const TextInput = ({
  className = '',
  defaultValue = '',
  error = false,
  errors = null,
  errorClassName = '',
  inputProps,
  inputPropsAdornments,
  label = '',
  labelColor,
  multiline = false,
  name,
  onChange,
  large = false,
  placeholder = '',
  register = null,
  rows = 0,
  type = 'string',
  variant = 'outlined',
  onEnterAction,
  ...rest
}: TextInputInterface) => {
  if (className) className = ` ${className}`;
  const inputPropsRequired = inputProps && inputProps.required ? '*' : '';
  return large ? (
    <div className={`textfield__large`}>
      {label !== '' && (
        <p
          className={`textfield__label`}
          style={{ color: labelColor }}
        >{`${label}${inputPropsRequired}`}</p>
      )}
      <TextField
        className={`textfield__main${className}`}
        defaultValue={defaultValue}
        error={error}
        id={name}
        inputProps={inputProps}
        InputProps={{
          style: {
            backgroundColor: 'white',
          },
          ...inputPropsAdornments,
        }}
        multiline={multiline}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        minRows={rows}
        type={type}
        variant={variant}
        {...(register && register)}
        {...rest}
      />
      {errors && errors[name] && <ErrorMessage className={errorClassName} />}
    </div>
  ) : (
    <Fragment>
      {label !== '' && (
        <p
          className={`textfield__label`}
          style={{ color: labelColor }}
        >{`${label}`}</p>
      )}
      <TextField
        onKeyDown={(event) => {
          if (event.key === 'Enter' && onEnterAction) {
            onEnterAction();
          }
        }}
        className={`textfield__main${className}`}
        defaultValue={defaultValue}
        id={name}
        inputProps={inputProps}
        InputProps={{
          style: {
            backgroundColor: 'white',
          },
          ...inputPropsAdornments,
        }}
        multiline={multiline}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        minRows={rows}
        type={type}
        variant={variant}
        {...(register && register)}
        {...rest}
      />
    </Fragment>
  );
};

export default TextInput;

import React, { useEffect, useState } from 'react';
import Select from '../Atoms/Select/Select';

import './SimpleTableHeader.scss';

export interface ActionSimpleTableInterface {
  text: string;
  value: string;
  action: (payload?: any) => void;
}

interface SimpleTableHeaderSchema {
  selected: string[];
  singleActionsTable?: ActionSimpleTableInterface[];
  multiActionsTable?: ActionSimpleTableInterface[];
  singularItemTitle?: string;
  pluralItemTitle?: string;
}

const SimpleTableHeader = ({
  selected,
  singleActionsTable = [],
  multiActionsTable = [],
  singularItemTitle,
  pluralItemTitle,
}: SimpleTableHeaderSchema): JSX.Element => {
  const [selectedAction, setSelectedAction] = useState<any>(null);

  useEffect(() => {
    setSelectedAction(null);
  }, [selected]);

  useEffect(() => {
    if (selectedAction) {
      const data =
        selected.length === 1 ? singleActionsTable : multiActionsTable;
      const action = data.find((row) => {
        return row.value === selectedAction.value;
      });

      if (action) {
        action.action(selected);
      }
    }
  }, [selectedAction]);

  const getSelectedColumnsName = () => {
    if (singularItemTitle && pluralItemTitle) {
      return selected.length === 1 ? singularItemTitle : pluralItemTitle;
    }

    return selected.length === 1 ? 'item' : 'items';
  };

  return (
    <div className="selected-actions__main_st">
      <div className="selected__field" style={{ width: '230px' }}>
        <Select
          data={selected.length === 1 ? singleActionsTable : multiActionsTable}
          label=""
          name="select_actions"
          onChange={(_event: any, newValue?: any) => {
            if (newValue) setSelectedAction(newValue);
            else setSelectedAction(null);
          }}
          placeholder={'Acciones'}
          value={selectedAction}
        />
      </div>
      <p className="selected-text">
        Se {selected.length === 1 ? 'ha' : 'han'} seleccionado{' '}
        <span className="selected-text--number">{selected.length}</span>
        {` ${getSelectedColumnsName()}`}
      </p>
    </div>
  );
};

export default SimpleTableHeader;

import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { DatePicker as MaterialDatePicker } from '@mui/x-date-pickers/DatePicker';
import { es } from 'date-fns/locale';
import DayjsUtils from "@date-io/dayjs";
import { esES } from '@mui/x-date-pickers/locales';
import './Datepicker.scss';

interface DatepickerInferface {
  label?: string;
  placeholder?: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
}

export default function DatePicker({
  label = '',
  placeholder = 'Seleccionar fecha',
  value,
  onChange,
  disabled = false,
}: DatepickerInferface) {
  //const [value, setValue] = React.useState<Date | null>(null);

  const dateOnChange = (date: Date | null) => {
    onChange(date);
  };
  return (
    <div className="date-picker__main">
      <LocalizationProvider  localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} dateAdapter={AdapterDateFns}>
        {label !== '' && <p className={`textfield__label`}>{`${label}`}</p>}
        <MaterialDatePicker
          inputFormat={'dd/MM/yyyy'}
          toolbarPlaceholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={dateOnChange}
          renderInput={(params) => (
            <TextField
              className="text-field"
              {...params}
              placeholder={placeholder}
            />
          )}
          components={{
            OpenPickerIcon: DateRangeIcon,
          }}
        />
      </LocalizationProvider>
    </div>
  );
}

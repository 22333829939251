import React from "react";

import "./ActivationTable.scss";
import ActivationTableRow, {
  ActivationTableRowData,
} from "./ActivationTableRow";
import { Checkbox } from "@mui/material";

export const FULL_COLUMN_SPAN = 8;

interface ActivationTableSchema {
  dataSource: ActivationTableRowData[];
  setDatasource: (newDatasource: ActivationTableRowData[]) => void;
}

function ActivationTable({ dataSource, setDatasource }: ActivationTableSchema) {
  return (
    <table className="request_activation_main_table">
      <thead>
        <tr>
          {/*<th className="request_activation_main_table_checkbox_container">*/}
          {/*  <Checkbox*/}
          {/*    className="request_activation_checkbox"*/}
          {/*    // checked={false}*/}
          {/*    onChange={() => {*/}
          {/*      console.log('check');*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</th>*/}
          <th className="request_activation_main_table_checkbox_container">
            <span>Marcas</span>
          </th>
          <th>
            <span>Tiendas</span>
          </th>
          <th>
            <span>Fechas</span>
          </th>
          <th>
            <span>Días</span>
          </th>
          <th>
            <span>Colaboradores</span>
          </th>
          <th>
            <span>Subtotal</span>
          </th>
          <th>
            <span></span>
          </th>
        </tr>
      </thead>
      <tbody>
        {dataSource.length > 0 &&
          dataSource.map((row, idx) => (
            <ActivationTableRow
              data={row}
              isLastRow={dataSource.length === idx + 1}
              dataSource={dataSource}
              setDatasource={setDatasource}
            />
          ))}
        {dataSource.length === 0 && (
          <tr>
            <td
              colSpan={FULL_COLUMN_SPAN}
              className={"activation_table_empty_rows_td"}
            >
              <span>
                No se cuenta con ninguna marca asociada a esta solicitud de
                activación.
              </span>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default ActivationTable;

import MainBackendAxios from "../utils/MainBackendAxios";
import { BrandCreate } from "./interfaces/BrandInterface";
import { FunctionsHelper } from "../utils/FunctionsHelper";

const BrandService = {
    get: async (): Promise<any> => {

        try {
            const response = await MainBackendAxios.get(`/brand/get-all`);
            return {
                success: true,
                data: response?.data?.data,
            };
        } catch (err) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getById: async (id: string): Promise<any> => {

        try {
            const response = await MainBackendAxios.get(`/brand/find-by-id/${id}`);
            let user: any | null = null;

            return {
                success: true,
                data: user,
            };
        } catch (err) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    create: async (brandData:BrandCreate): Promise<any> => {

        try {
            const response = await MainBackendAxios.post(`/brand/create`,brandData);
        
            return {
                success: true,
                logout: true,
            };
        } catch (err) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    update: async (brandData:BrandCreate,id:string): Promise<any> => {

      try {
          const response = await MainBackendAxios.put(`/brand/update/${id}`,brandData);
      
          return {
              success: true,
              logout: true,
          };
      } catch (err) {
        return FunctionsHelper.getErrorFormat(err);
      }
    },
    delete: async (id:string): Promise<any> => {
      try {
          const response = await MainBackendAxios.delete(`/brand/delete/${id}`);
      
          return {
              success: true,
              logout: true,
          };
      } catch (err) {
        return FunctionsHelper.getErrorFormat(err);
      }
    },
};

export default BrandService;

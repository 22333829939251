import React from 'react';

import './FilterDetail.scss';
import { FilterDetailSchema } from './FilterDetail.types';
import { Chip } from '@mui/material';

const FilterDetail = ({
  filters,
  cleanFilters,
  removeFilter,
  loading,
  hideClearAllFilters = false,
}: FilterDetailSchema): JSX.Element => {
  const showClean = () => {
    return (
      filters.filter((filterRow) => {
        return !filterRow.disableRemove;
      }).length > 0
    );
  };

  return (
    <div className="show-filters__main">
      <div className="filters__options">
        {/*<p className="filters-length">*/}
        {/*  Filtros: <span>{filters.length}</span>*/}
        {/*</p>*/}
        {showClean() && !hideClearAllFilters && (
          <div
            className="clean-filter__content"
            onClick={loading ? undefined : cleanFilters}
          >
            <p className="clean-filter">
              {loading ? 'Cargando...' : 'Limpiar Filtros'}
            </p>
          </div>
        )}
      </div>
      <div className="filters__items">
        {filters.map((filter) => (
          <Chip
            key={filter.key}
            label={
              <>
                <span className="key-text">{filter.label}: </span>
                <span className="value-text">{filter.detail}</span>
              </>
            }
            onDelete={
              filter.disableRemove || loading
                ? undefined
                : () => removeFilter(filter.key)
            }
          />
        ))}
      </div>
    </div>
  );
};

export default FilterDetail;

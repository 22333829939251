import { Children, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../styles.scss';


const EndHeader = (props: any) => {
    
    return (
        <div className="EndSide">
            <div className={`esidebar`}>
                {props.children}
            </div>
        </div>
    )
}

export default EndHeader;
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import BasicTabs from "../../components/Tabs/Tabs";
import ActivationService from "../../services/ActivationService";
import StoreService from "../../services/StoreService";
import TitaskExternalService from "../../services/TitaskExternalService";
import {
  ActivationDetail as ActivationDetailInterface,
  Payment,
} from "../../services/interfaces/ActivationInterface";
import { Worker } from "../../services/interfaces/TitaskExternalInterface";
import GeneralDetail from "./components/GeneralDetail";
import WorkerDetail from "./components/WorkerDetail";
import "./styles.scss";
import Header from "../Header/Header";
import LoaderLocal from "../../components/Atoms/LoaderLocal/LoaderLocal";
import OrderDetail from "./components/OrderDetail";

const ActivationDetail = (): JSX.Element => {
  const history = useNavigate();
  const { activationId }: any = useParams();
  const [loading, setLoading] = useState(true);
  const [activation, setActivation] =
    useState<ActivationDetailInterface | null>(null);
  const [payment, setPayment] = useState<Payment | null>(null);
  const [workers, setWorkers] = useState<Worker[]>([]);
  const [stores, setStores] = useState<any[]>([]);
  const heightOffset = 140;
  const vh = window.innerHeight;
  const vw = window.innerWidth;
  const [loadingOc, setLoadingOc] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const [activationResponse, paymentResponse, workerResponse, storeResponse] =
      await Promise.all([
        ActivationService.getById(activationId),
        ActivationService.getPaymentByActivationId(activationId),
        TitaskExternalService.getAllWorkersByActivation(activationId),
        StoreService.get(),
      ]);

    if (activationResponse.success) {
      if (
        paymentResponse.success &&
        workerResponse.success &&
        storeResponse.success
      ) {
        setActivation(activationResponse.data);
        setPayment(paymentResponse.data);
        setStores(storeResponse.data);
        setWorkers(workerResponse.data);
        setLoading(false);
      } else {
        console.log(workerResponse, paymentResponse, storeResponse);
        toast.error(
          `No se pudo cargar los datos adicionales de la activación.`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    } else {
      const errorMessage =
        activationResponse.data || "Ocurrió un error en el servidor (500).";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const uploadPurchaseOrder = async (file: File) => {
    if (!loadingOc) {
      setLoadingOc(true);

      const response = await ActivationService.uploadPurchaseOrder(
        activationId,
        file
      );

      if (response.success) {
        const activationResponse = await ActivationService.getById(
          activationId
        );
        if (activationResponse.success) {
          setActivation(activationResponse.data);
        } else {
          const errorMessage =
            activationResponse.data || "Ocurrió un error en el servidor (500).";
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        const errorMessage =
          response.data || "Ocurrió un error en el servidor (500).";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

      setLoadingOc(false);
    }
  };

  const tabsData = [
    {
      label: "Detalles generales",
      children: (
        <GeneralDetail
          activationDetail={activation}
          payment={payment}
          stores={stores}
        />
      ),
    },
    {
      label: "Personas",
      children: (
        <WorkerDetail activationDetail={activation} workers={workers} />
      ),
    },
    {
      label: "Orden de compra",
      children: (
        <OrderDetail
          activationDetail={activation}
          submit={uploadPurchaseOrder}
          loading={loadingOc}
        />
      ),
    },
  ];

  return (
    <Header>
      {loading ? (
        <LoaderLocal />
      ) : (
        <div
          className="app__main"
          style={{
            width: `${vw}px`,
            height: `${vh - heightOffset}px`,
          }}
        >
          <div className="app__content">
            <div
              className="activation_detail_back_container"
              onClick={() => {
                history(-1);
              }}
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5.84248 11.0326C5.91278 10.9629 5.96857 10.8799 6.00665 10.7885C6.04473 10.6971 6.06433 10.5991 6.06433 10.5001C6.06433 10.4011 6.04473 10.3031 6.00665 10.2117C5.96857 10.1203 5.91278 10.0373 5.84248 9.96761L2.40749 6.53261C2.33719 6.46288 2.2814 6.37993 2.24332 6.28854C2.20525 6.19714 2.18564 6.09912 2.18564 6.00011C2.18564 5.9011 2.20525 5.80307 2.24332 5.71167C2.2814 5.62028 2.33719 5.53733 2.40749 5.46761L5.84248 2.03261C5.91278 1.96288 5.96857 1.87993 6.00665 1.78854C6.04473 1.69714 6.06433 1.59912 6.06433 1.50011C6.06433 1.4011 6.04473 1.30307 6.00665 1.21167C5.96857 1.12028 5.91278 1.03733 5.84248 0.967606C5.70196 0.827918 5.51187 0.749512 5.31373 0.749512C5.11559 0.749512 4.92551 0.827918 4.78498 0.967606L1.34249 4.41011C0.921141 4.83198 0.684472 5.40385 0.684472 6.00011C0.684472 6.59636 0.921141 7.16823 1.34249 7.59011L4.78498 11.0326C4.92551 11.1723 5.11559 11.2507 5.31373 11.2507C5.51187 11.2507 5.70196 11.1723 5.84248 11.0326Z" />
              </svg>
              <span>Atrás</span>
            </div>
            <BasicTabs data={tabsData} />
          </div>
        </div>
      )}
    </Header>
  );
};
export default ActivationDetail;

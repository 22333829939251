import MainBackendAxios from "../utils/MainBackendAxios";
import { Filter } from './interfaces/TurnActivation';
import { FunctionsHelper } from "../utils/FunctionsHelper";

const TurnActivationService = {
    getAllTurnsSchedule: async (dataFilter:Filter): Promise<any>=> {
        try {
            const response = await MainBackendAxios.post(
                `/titask-external/get-all-turns-schedule`,
                dataFilter
              );
            return {
                success: true,
                data: response.data?.data,
              };
        } catch (err) {
          return FunctionsHelper.getErrorFormat(err);
        }
    },
    getById: async (id: number): Promise<any> => {
        try {
          const response = await MainBackendAxios.get(`/titask-external/find-turn-detail/${id}`);
    
          return {
            success: true,
            data: response.data?.data,
          };
        } catch (err) {
          return FunctionsHelper.getErrorFormat(err);
        }
      },

}

export default TurnActivationService;
import React from 'react';
import DateObject from 'react-date-object';
import { useSelector } from 'react-redux';

import trashIcon from '../../../assets/icons/trash.svg';
import brandCoke from '../../../assets/images/marca_coke.png';
import NumberInput from '../../../components/Atoms/InputNumber/InputNumber';
import { Tenant } from '../../../models/Tenant';
import ActivationCalendar from './ActivationCalendar';
import { FULL_COLUMN_SPAN } from './ActivationTable';
import { Checkbox } from '@mui/material';
import moment from 'moment';

export interface ActivationTableRowStoreData {
  id: number;
  name: string;
  dates: string[];
  numberOfCollaborators: number;
}

export interface ActivationTableRowData {
  requestNumber: string;
  brandId: number;
  brandName: string;
  stores: ActivationTableRowStoreData[];
}

interface ActivationTableRowSchema {
  data: ActivationTableRowData;
  isLastRow?: boolean;
  dataSource: ActivationTableRowData[];
  setDatasource: (newDatasource: ActivationTableRowData[]) => void;
}

function ActivationTableRow({
  data,
  isLastRow = false,
  dataSource,
  setDatasource,
}: ActivationTableRowSchema) {
  const tenantClient: Tenant = useSelector((state: any) => state.tenant);
  const staticPricePerActivation = tenantClient.activationConfig.defaultActivationPrice;
  const moneySymbol = tenantClient.currency.symbol;

  const getSubtotal = (store: ActivationTableRowStoreData) => {
    if (
      store.dates.length === 0 ||
      !store.numberOfCollaborators ||
      store.numberOfCollaborators === 0
    ) {
      return 0;
    } else {
      return (
        store.dates.length *
        store.numberOfCollaborators *
        staticPricePerActivation
      );
    }
  };

  const updateStoreCollaboratorsNumber = (
    storeIdx: number,
    store: ActivationTableRowStoreData,
    newValue: number
  ) => {
    const newStore = { ...store, numberOfCollaborators: newValue };
    const localDatasource = [...dataSource];
    const brandIdx = localDatasource.findIndex((row) => {
      return (
        row.brandId === data.brandId &&
        row.stores.find((storeRow) => {
          return storeRow.id === store.id;
        })
      );
    });
    const localStores = [...localDatasource[brandIdx].stores];
    localStores[storeIdx] = newStore;
    localDatasource[brandIdx].stores = localStores;
    setDatasource(localDatasource);
  };

  const getStoreDatesFormat = (
    store: ActivationTableRowStoreData
  ): DateObject[] => {
    return store.dates.map((date) => {
      const momentDate = moment(date, 'YYYY-MM-DD');
      return new DateObject(momentDate.toDate());
    });
  };

  const setStoreDates = (
    storeIdx: number,
    store: ActivationTableRowStoreData,
    newDates: DateObject[]
  ) => {
    const localDatasource = [...dataSource];
    const brandIdx = localDatasource.findIndex((row) => {
      return (
        row.brandId === data.brandId &&
        row.stores.find((storeRow) => {
          return storeRow.id === store.id;
        })
      );
    });
    const localStores = [...localDatasource[brandIdx].stores];
    const localStore = localStores[storeIdx];
    localStore.dates = newDates
      .map((newDate) => {
        return newDate.format('YYYY-MM-DD');
      })
      .sort((a, b) => moment(a, 'YYYY-MM-DD').diff(moment(b, 'YYYY-MM-DD')));
    localStores[storeIdx] = localStore;
    localDatasource[brandIdx].stores = localStores;
    setDatasource(localDatasource);
  };

  const remove = (storeIdx: number, store: ActivationTableRowStoreData) => {
    const localDatasource = [...dataSource];

    const brandIdx = localDatasource.findIndex((row) => {
      return row.brandId === data.brandId;
    });

    if (brandIdx !== -1) {
      const localStores = [...localDatasource[brandIdx].stores];
      const storesFiltered = localStores.filter(
        (localStore) => localStore.id !== store.id
      );

      if (storesFiltered.length > 0) {
        localDatasource[brandIdx].stores = storesFiltered;
      } else {
        localDatasource.splice(brandIdx, 1);
      }

      setDatasource(localDatasource);
    }
  };

  return (
    <>
      <tr>
        <td
          colSpan={FULL_COLUMN_SPAN}
          className="request_activation_main_table_request_number_td"
        >
          {' '}
          <span style={{ marginLeft: '25px' }}>
            Activación N° {data.requestNumber}
          </span>
        </td>
      </tr>

      {data.stores.map((storeData, idx) => (
        <tr>
          {idx === 0 && (
            <>
              {/*<td*/}
              {/*  className="request_activation_main_table_checkbox_container"*/}
              {/*  rowSpan={data.stores.length}*/}
              {/*>*/}
              {/*  <Checkbox*/}
              {/*    className="request_activation_checkbox"*/}
              {/*    onChange={() => {*/}
              {/*      console.log('check');*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</td>*/}
              <td rowSpan={data.stores.length}>
                <div className="request_activation_main_table_brand_container">
                  <span>{data.brandName}</span>
                  <img
                    className="request_activation_main_table_brand_logo"
                    src={brandCoke}
                    alt="brand_logo"
                  />
                </div>
              </td>
            </>
          )}
          <td className="request_activation_main_table_store_name">
            {storeData.name}
          </td>
          {/*<td>{storeData.dates.length > 0 ? storeData.dates[0] : ''}</td>*/}
          <td>
            <ActivationCalendar
              selectedDates={getStoreDatesFormat(storeData)}
              setSelectedDates={(newDates: DateObject[]) => {
                setStoreDates(idx, storeData, newDates);
              }}
            />
          </td>
          <td>{storeData.dates.length}</td>
          <td>
            <NumberInput
              className="request_activation_number_input_flex"
              aria-label="cantidad-colaboradores"
              min={1}
              max={2000}
              value={storeData.numberOfCollaborators}
              onChange={(event, val: any) => {
                updateStoreCollaboratorsNumber(idx, storeData, val);
              }}
            />
          </td>
          <td className="request_activation_main_table_subtotal">
            {moneySymbol + ' '}
            {getSubtotal(storeData)}
          </td>
          <td>
            <img
              className={`request_activation_trash_icon`}
              src={trashIcon}
              alt="Eliminar"
              onClick={() => {
                remove(idx, storeData);
              }}
            />
          </td>
        </tr>
      ))}
      <tr
        className={`request_activation_last_row_separation${
          isLastRow ? ' request_activation_last_table_row' : ''
        }`}
      >
        <td colSpan={FULL_COLUMN_SPAN}></td>
      </tr>
    </>
  );
}

export default ActivationTableRow;

import * as React from 'react';

import './Tabs.scss';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface DataInterface {
  label: string;
  children: React.ReactNode;
}

interface BasicTabsInterface {
  data: DataInterface[];
  changeCurrentTab?: (tab: number) => void;
  getCurrentTab?: (tab: number) => void;
  disabled?: boolean;
  initialTab?: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="tab-panel tab-panel-item"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <React.Fragment>{children}</React.Fragment>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BasicTabs = ({
  data = [],
  changeCurrentTab,
  getCurrentTab,
  disabled,
  initialTab = 0,
}: BasicTabsInterface) => {
  const tabsData = data.filter(
    (row: DataInterface) => row.children !== undefined
  );
  const [value, setValue] = React.useState(initialTab);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (!disabled) {
      setValue(newValue);
      if (changeCurrentTab) changeCurrentTab(newValue);
      if (getCurrentTab) getCurrentTab(newValue);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        className="second_box"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          variant="standard"
          // disabled={disabled}
        >
          {tabsData?.length > 0 &&
            tabsData.map((tab, index) => (
              <Tab key={index} label={tab.label} {...a11yProps(index)} />
            ))}
        </Tabs>
      </Box>
      {tabsData?.length > 0 &&
        tabsData.map((tab, index) => (
          <TabPanel value={value} index={index} key={index}>
            {tab.children}
          </TabPanel>
        ))}
    </Box>
  );
};

export default BasicTabs;

import React from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';

import calendar from '../../../assets/images/fi-rr-calendar.svg';
import { IconButton } from '@mui/material';

interface ActivationCalendarSchema {
  selectedDates: DateObject[];
  setSelectedDates: (selectedDates: DateObject[]) => void;
}

function ActivationCalendar({
  selectedDates,
  setSelectedDates,
}: ActivationCalendarSchema) {
  const handleDateChange = (newDates: DateObject | DateObject[]) => {
    const datesArray = Array.isArray(newDates) ? newDates : [newDates];
    setSelectedDates(datesArray);
  };

  return (
    <DatePicker
      format={'DD/MM/AAAA'}
      placeholder="DD/MM/AAAA"
      value={selectedDates}
      multiple
      render={(value, openCalendar) => {
        return (
          <div style={{ cursor: 'pointer' }} onClick={openCalendar}>
            <IconButton>
              <img src={calendar} alt="calendar-icon" />
            </IconButton>
            <span>
              {selectedDates.length > 0
                ? selectedDates[0].format('DD/MM/YYYY')
                : 'DD/MM/AAAA'}
            </span>
          </div>
        );
      }}
      onChange={handleDateChange}
      months={[
        ['Enero', 'Ene.'],
        ['Febrero', 'Feb.'],
        ['Marzo', 'Mar.'],
        ['Abril', 'Abr.'],
        ['Mayo', 'May.'],
        ['Junio', 'Jun.'],
        ['Julio', 'Jul.'],
        ['Agosto', 'Ago.'],
        ['Septiembre', 'Sep.'],
        ['Octubre', 'Oct.'],
        ['Noviembre', 'Nov.'],
        ['Diciembre', 'Dic.'],
      ]}
      weekDays={['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']}
    />
  );
}

export default ActivationCalendar;

import MainBackendAxios from "../utils/MainBackendAxios";
import { Worker } from './interfaces/TitaskExternalInterface';
import { FunctionsHelper } from "../utils/FunctionsHelper";

const TitaskExternalService = {
  getAllWorkersByActivation: async (activationId: string): Promise<any> => {
    try {
      const response = await MainBackendAxios.get(
        `/titask-external/get-all-workers-by-activation/${activationId}`
      );
      return {
        success: true,
        data: response.data?.data as Worker[],
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
};

export default TitaskExternalService;

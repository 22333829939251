import MainBackendAxios from "../utils/MainBackendAxios";
import { FunctionsHelper } from "../utils/FunctionsHelper";

const StoreService = {
  get: async (): Promise<any> => {
    try {
      const response = await MainBackendAxios.get(`/store/get-all`);
      return {
        success: true,
        data: response?.data?.data,
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  getById: async (id: string): Promise<any> => {
    try {
      const response = await MainBackendAxios.get(`/store/find-by-id/${id}`);
      let user: any | null = null;

      return {
        success: true,
        data: user,
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  create: async (name: string): Promise<any> => {
    try {
      const response = await MainBackendAxios.post(`/store/create`, { name });

      return {
        success: true,
        logout: true,
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  update: async (name: string, id: string): Promise<any> => {
    try {
      const response = await MainBackendAxios.put(`/store/update/${id}`, { name });

      return {
        success: true,
        logout: true,
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  delete: async (id: string): Promise<any> => {
    try {
      const response = await MainBackendAxios.delete(`/store/delete/${id}`);

      return {
        success: true,
        logout: true,
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
};

export default StoreService;

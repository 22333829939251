import React, { useRef } from "react";
import { ActivationDetail } from "../../../services/interfaces/ActivationInterface";
import moment from "moment/moment";
import TenantButton from "../../../components/TenantButton/TenantButton";
import { ReactComponent as PdfIcon } from "../../../assets/icons/pdf-file.svg";
import { FunctionsHelper } from "../../../utils/FunctionsHelper";

export interface OrderDetailSchema {
  activationDetail: ActivationDetail | null;
  submit: (file: File) => void;
  loading: boolean;
}

function OrderDetail({ activationDetail, submit, loading }: OrderDetailSchema) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file && file.type === "application/pdf") {
      submit(file);
    } else {
      alert("Por favor, seleccione un archivo PDF.");
    }
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current && !loading) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      {activationDetail && (
        <div>
          <span className="activation_detail_general_detail_text_date">
            {moment(activationDetail.createdAt).format("DD/MM/YYYY")}
          </span>
          <h1 className="activation_detail_general_detail_text_title">
            Orden de compra
          </h1>
          {!activationDetail.lastPurchaseOrder && (
            <div style={{ width: "260px", marginTop: "10px" }}>
              <TenantButton
                text={
                  loading ? (
                    "Subiendo documento..."
                  ) : (
                    <>
                      <PdfIcon width={25} />
                      <span>Cargar documento</span>
                    </>
                  )
                }
                action={handleUploadButtonClick}
                fullwidth
                disabled={loading}
              />
              <input
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </div>
          )}
          {activationDetail.lastPurchaseOrder && (
            <>
              <h2 className="activation_detail_general_detail_text_brand">
                Fec. subida:{" "}
                {moment(activationDetail.lastPurchaseOrder.createdAt).format(
                  "DD/MM/YYYY h:mm:ss A"
                )}
              </h2>
              <iframe
                src={FunctionsHelper.showS3FileUrl(
                  activationDetail.lastPurchaseOrder.s3key
                )}
                title={`Orden de compra`}
                frameBorder={0}
                style={{
                  width: `100%`,
                  marginTop: "20px",
                  minHeight: "250px",
                  height: `500px`,
                }}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default OrderDetail;
